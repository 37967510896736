import AddIcon from '@mui/icons-material/Add'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Button, LinearProgress, IconButton } from '@mui/material'
import EmojiPicker from 'emoji-picker-react'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

function isSame(arrA, arrB) {
  return arrA.length === arrB.length && arrA.every((v, index) => v === arrB[index])
}

const STEPS = {
  LIST: 'LIST',
  ADD: 'ADD',
}

const EmojisSelector = ({ value, max = 3, onChange }) => {
  const [emojis, setEmojis] = useState(value)

  const [step, setStep] = useState(STEPS.LIST)
  const [editedIndex, setEditedIndex] = useState()

  const addEmoji = useCallback(
    (newEmoji) => {
      const newEmojis = [...emojis].concat(newEmoji)
      setEmojis([...newEmojis])
      if (onChange) {
        onChange([...newEmojis])
      }
    },
    [emojis, onChange]
  )

  const removeEmoji = useCallback(
    (index) => {
      const newEmojis = emojis.filter((_, i) => i !== index)
      setEmojis([...newEmojis])
      if (onChange) {
        onChange([...newEmojis])
      }
    },
    [emojis, onChange]
  )

  const updateEmoji = useCallback(
    (index, newEmoji) => {
      const newEmojis = emojis.map((emoji, i) => (i === index ? newEmoji : emoji))
      setEmojis([...newEmojis])
      if (onChange) {
        onChange([...newEmojis])
      }
    },
    [emojis, onChange]
  )

  useEffect(() => {
    setEmojis([...value])
  }, [value])

  return (
    <div>
      {step === STEPS.ADD && (
        <>
          <h3>Add emoji</h3>
          <Button variant="contained" color="error" onClick={() => setStep(STEPS.LIST)} size="small">
            Cancel
          </Button>
        </>
      )}
      <EmojiPickerContainer>
        <EmojiPicker
          open={step === STEPS.ADD || typeof editedIndex !== 'undefined'}
          onEmojiClick={({ emoji }) => {
            if (step === STEPS.ADD) {
              addEmoji(emoji)
              setStep(STEPS.LIST)
            } else if (typeof editedIndex !== 'undefined') {
              updateEmoji(editedIndex, emoji)
              setEditedIndex(undefined)
            }
          }}
          emojiStyle="apple"
        />
      </EmojiPickerContainer>

      {step === STEPS.LIST && (
        <List>
          {emojis.map((emoji, index) => (
            <Item key={index}>
              <Emoji
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setEditedIndex(index)
                }}
                $edited={editedIndex === index}
              >
                {emoji}
              </Emoji>
              <Actions>
                <IconButton
                  size="small"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    removeEmoji(index)
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Actions>
            </Item>
          ))}
          {!max ||
            (emojis.length < max && (
              <AddItem
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setStep(STEPS.ADD)
                }}
              >
                <AddIcon />
              </AddItem>
            ))}
        </List>
      )}
    </div>
  )
}

export default EmojisSelector

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 12px;
  margin: 0;
  padding: 0;
  list-style: none;
`

const Actions = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  margin: -18px -14px 0 0;
`

const Item = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  margin: 0;

  ${Actions} {
    display: none;
  }

  &:hover ${Actions} {
    display: block;
  }
`

const Emoji = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 34px;

  &:hover {
    background: white;
    cursor: pointer;
  }

  ${(props) =>
    props.$edited &&
    css`
      border-color: orange;
    `}
`

const AddItem = styled(IconButton)``

const EmojiPickerContainer = styled.div`
  /* position: absolute; */
`
