export const WEATHER_TYPES = [
  {
    value: 'blowingDust',
    label: 'Blowing dust or sandstorm.',
  },
  {
    value: 'clear',
    label: 'Clear.',
  },
  {
    value: 'cloudy',
    label: 'Cloudy.',
  },
  {
    value: 'foggy',
    label: 'Fog.',
  },
  {
    value: 'haze',
    label: 'Haze.',
  },
  {
    value: 'mostlyClear',
    label: 'Mostly clear.',
  },
  {
    value: 'mostlyCloudy',
    label: 'Mostly cloudy.',
  },
  {
    value: 'partlyCloudy',
    label: 'Partly cloudy.',
  },
  {
    value: 'smoky',
    label: 'Smoky.',
  },
  {
    value: 'breezy',
    label: 'Breezy, light wind.',
  },
  {
    value: 'windy',
    label: 'Windy.',
  },
  {
    value: 'drizzle',
    label: 'Drizzle or light rain.',
  },
  {
    value: 'heavyRain',
    label: 'Heavy rain.',
  },
  {
    value: 'isolatedThunderstorms',
    label: 'Thunderstorms covering less than 1/8 of the forecast area.',
  },
  {
    value: 'rain',
    label: 'Rain.',
  },
  {
    value: 'sunShowers',
    label: 'Rain with visible sun.',
  },
  {
    value: 'scatteredThunderstorms',
    label: 'Numerous thunderstorms spread across up to 50% of the forecast area.',
  },
  {
    value: 'strongStorms',
    label: 'Notably strong thunderstorms.',
  },
  {
    value: 'thunderstorms',
    label: 'Thunderstorms.',
  },
  {
    value: 'frigid',
    label: 'Frigid conditions, low temperatures, or ice crystals.',
  },
  {
    value: 'hail',
    label: 'Hail.',
  },
  {
    value: 'hot',
    label: 'High temperatures.',
  },
  {
    value: 'flurries',
    label: 'Flurries or light snow.',
  },
  {
    value: 'sleet',
    label: 'Sleet.',
  },
  {
    value: 'snow',
    label: 'Snow.',
  },
  {
    value: 'sunFlurries',
    label: 'Snow flurries with visible sun.',
  },
  {
    value: 'wintryMix',
    label: 'Wintry mix.',
  },
  {
    value: 'blizzard',
    label: 'Blizzard.',
  },
  {
    value: 'blowingSnow',
    label: 'Blowing or drifting snow.',
  },
  {
    value: 'freezingDrizzle',
    label: 'Freezing drizzle or light rain.',
  },
  {
    value: 'freezingRain',
    label: 'Freezing rain.',
  },
  {
    value: 'heavySnow',
    label: 'Heavy snow.',
  },
  {
    value: 'hurricane',
    label: 'Hurricane.',
  },
  {
    value: 'tropicalStorm',
    label: 'Tropical storm.',
  },
]
