import ImageKit from 'imagekit-javascript'

const imagekit = new ImageKit({
  urlEndpoint: 'https://ik.imagekit.io/ukuwtf',
})

function computeSize(number) {
  let roundValue = 100
  if (number <= 150) {
    roundValue = 10
  } else if (number <= 300) {
    roundValue = 50
  }
  return Math.ceil(number / roundValue) * roundValue
}

const buildTransformation = (transformation = {}, mediaType) => {
  const { width, height, grayscale, blur, ...rest } = transformation || {}

  const finalTransformation = rest

  if (mediaType === 'image') {
    finalTransformation.format = 'webp'
  }

  if (blur) {
    finalTransformation.blur = String(blur)
  }

  if (grayscale) {
    finalTransformation.effectGray = ''
  }

  if (width) {
    finalTransformation.width = String(computeSize(width))
  }

  if (height) {
    finalTransformation.height = String(computeSize(height))
  }

  return finalTransformation
}

export function computeImageKitUrl(path, transformation = {}, mediaType) {
  if (!path || path.startsWith('http')) return null

  return imagekit.url({
    path,
    transformation: [buildTransformation(transformation, mediaType)],
  })
}

export function computeTransformationParams(transformation = {}, mediaType) {
  const url = imagekit.url({
    path: 'whatever',
    transformation: [buildTransformation(transformation, mediaType)],
    transformationPosition: 'query',
  })
  return url.split('?')[1]
}
