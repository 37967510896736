/* eslint-disable max-len */
import { Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import Slider from '@mui/material/Slider'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { PARAMETERS, SECTIONS_CONFIG, TREE } from './AlgoPOVParametersConfig'

export default function AlgoPOVParametersSection({ treeItem, values, handleChange, isRoot, filter, changedValues }) {
  return (
    <Section $isRoot={isRoot} $hasBorder={!filter}>
      {!filter && <SectionTitle $isRoot={isRoot}>{treeItem.title}</SectionTitle>}
      {!!treeItem.description && !filter && (
        <Description>
          <Typography gutterBottom variant="caption">
            {treeItem.description}
          </Typography>
        </Description>
      )}
      {!!treeItem.parameters &&
        treeItem.parameters
          .filter((config) => {
            if (!filter) {
              return true
            }
            const searchStr = filter?.toLowerCase()
            return (
              config.title?.toLowerCase().includes(searchStr) ||
              treeItem.title?.toLowerCase().includes(searchStr) ||
              config.description?.toLowerCase().includes(searchStr)
            )
          })
          .map((config) => (
            <ItemContainer
              key={config.key}
              $hasChanged={
                changedValues?.[config.key] && changedValues[config.key].previous !== changedValues[config.key].current
              }
            >
              <Tooltip title={config.impact} placement="left">
                <>
                  <Typography gutterBottom variant="caption">
                    {config.title}
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Slider
                        size="small"
                        value={values[config.key] || PARAMETERS[config.key]?.defaultValue}
                        onChange={(_, val) => {
                          handleChange(config.key, val)
                        }}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        step={config.step || 1}
                        min={config.min}
                        max={config.max}
                      />
                    </Grid>
                    <Grid item>
                      <ItemValue>
                        <span>
                          {values[config.key]}
                          {config.unit ? ` ${config.unit}` : ''}
                        </span>
                        <span>
                          {changedValues?.[config.key] &&
                          changedValues[config.key].previous !== changedValues[config.key].current
                            ? `(was ${changedValues[config.key].previous})`
                            : ''}
                        </span>
                      </ItemValue>
                    </Grid>
                  </Grid>
                </>
              </Tooltip>
            </ItemContainer>
          ))}
      {!!treeItem.children &&
        treeItem.children.map((childItem) => (
          <AlgoPOVParametersSection
            treeItem={childItem}
            key={childItem.key}
            values={values}
            handleChange={handleChange}
            filter={filter}
            changedValues={changedValues}
          />
        ))}
    </Section>
  )
}

const SectionTitle = styled.div`
  padding: 12px;
  color: #757ce8;

  ${(props) =>
    props.$isRoot &&
    css`
      color: white;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
    `}
`

const Section = styled.div`
  ${(props) =>
    !props.$isRoot &&
    css`
      margin-left: 12px;
    `}

  ${(props) =>
    props.$hasBorder &&
    css`
      &:not(:last-child) {
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      }
    `}
`

const Description = styled.div`
  font-size: 12px;
  opacity: 0.4;
  margin: 0 12px;
`

const ItemValue = styled.div`
  font-size: 12px;
  min-width: 60px;
  display: flex;
  flex-direction: column;
`

const ItemContainer = styled.div`
  padding: 12px;

  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  ${(props) =>
    props.$hasChanged &&
    css`
      color: orange !important;
    `}
`

const Container = styled.div`
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  background: black;
  // padding: 12px;
  font-size: 12px;
  overflow-y: scroll;
  flex: 1 1;
  height: 100%;
`
