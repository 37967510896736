import axios from 'axios'

import * as auth from './auth'

const token = auth.getToken()

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: token
    ? {
        common: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined,
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.status === 401) {
      auth.setToken()
      window.location = '/login'
    } else {
      return Promise.reject(error)
    }
  }
)

export function setToken(token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default api
