import { Autocomplete, Button, LinearProgress, TextField } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useStateContext } from '../../contexts/state.context'
import api from '../../services/api'
import Activity from '../Activity'

export const width = 300

export default function ActivitiesSelector() {
  const { tags, friends, users, activities, fetchActivities, user } = useStateContext()

  const [filters, setFilters] = useState({ userIds: [], tagIds: [] })
  const [loading, setLoading] = useState(false)

  const filteredActivities = useMemo(() => {
    return activities.filter((activity) => {
      if (filters.userIds?.length && !filters.userIds.includes(activity.UserId)) {
        return false
      }
      if (filters.tagIds?.length && !filters.tagIds.includes(activity.Tags?.[0]?.id)) {
        return false
      }
      return true
    })
  }, [activities, filters])

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value.map((u) => u.id) }))
  }, [])

  const confirm = useConfirm()
  const alert = useCallback(
    (title) => {
      return confirm({ title, hideCancelButton: true })
    },
    [confirm]
  )

  const classifyText = useMemo(() => {
    const count = filters?.userIds?.length || Object.keys(users)?.length
    if (!count) {
      return 'Queue all friends last 30 activities classification'
    } else if (count === 1) {
      return 'Queue this user last 30 activities classification'
    } else {
      return `Queue these ${count} users last 30 activities classification`
    }
  }, [filters?.userIds, users])

  const classify = useCallback(async () => {
    setLoading(true)
    try {
      await api.post('/activities/queue-classification', {
        userIds: filters?.userIds?.length ? filters.userIds : Object.keys(users),
        limit: 30,
      })
      await fetchActivities()
    } catch (err) {
      alert('Error while classifying activities')
    }
    setLoading(false)
  }, [alert, filters.userIds, users, fetchActivities])

  return (
    <Container>
      <Header>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={Object.values(users)}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          onChange={(event, newValue) => {
            handleFilterChange('userIds', newValue)
          }}
          value={filters.userIds.map((id) => users[id])}
          renderInput={(params) => <TextField {...params} label="Filter users" />}
          size="small"
        />
      </Header>
      <Inner>
        {loading && (
          <LoadingContainer>
            <LinearProgress />
          </LoadingContainer>
        )}
        <Button
          onClick={classify}
          style={{ width: '100%', fontSize: 12 }}
          variant="contained"
          size="small"
          disabled={loading || !Object.keys(users).length}
        >
          {classifyText}
        </Button>
      </Inner>
      <ActivitiesGrid>
        {filteredActivities?.length ? (
          <ul style={{ listStyle: 'none', padding: '12px 24px' }}>
            {filteredActivities.map((activity) => (
              <Activity
                key={activity.id}
                activity={activity}
                onChange={() => {
                  fetchActivities(friends)
                }}
              />
            ))}
          </ul>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, opacity: 0.7 }}>
            No activities
          </div>
        )}
      </ActivitiesGrid>
    </Container>
  )
}

const Inner = styled.div`
  position: relative;
  display: flex;
  padding: 12px 12px 0;
`

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 10px;
  /* top: 0; */
  z-index: 100;
  pointer-events: none;
`

const ActivitiesGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
`

const Container = styled.div`
  width: ${width}px;
  max-height: 100vh;
  display: flex;
  /* flex: 1 1; */
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
`

const Header = styled.div`
  padding: 4px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
