import FilterIcon from '@mui/icons-material/Filter'
import FolderCopyIcon from '@mui/icons-material/FolderCopy'
import PeopleIcon from '@mui/icons-material/People'
import PsychologyIcon from '@mui/icons-material/Psychology'
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const ITEMS = [
  {
    label: 'Categories',
    key: 'categories',
    Icon: FolderCopyIcon,
  },
  {
    label: 'Classification',
    key: 'classification',
    Icon: PsychologyIcon,
  },
  {
    label: 'POV',
    key: 'algoPOV',
    Icon: FilterIcon,
  },
  {
    label: 'Algo filters',
    key: 'algo-filters',
    Icon: FilterIcon,
  },
  {
    label: 'Interests',
    key: 'interests',
    Icon: FilterIcon,
  },
  {
    label: 'Users',
    key: 'users',
    Icon: PeopleIcon,
  },
  {
    label: 'Fake Profiles',
    key: 'fake-profiles',
    Icon: TheaterComedyIcon,
  },
]

export default function AppMenu() {
  const navigate = useNavigate()

  return (
    <List>
      {ITEMS.map((item) => (
        <ListItem key={item.key} disablePadding>
          <ListItemButton
            onClick={() => {
              navigate(item.key)
            }}
          >
            <ListItemIcon>
              <item.Icon />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
