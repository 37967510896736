import { Button, LinearProgress } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { WEATHER_TYPES } from './FakeProfiles.constants'
import EmojisSelector from '../../components/EmojisSelector'
import api from '../../services/api'

function formatEditedItemFromItem(item) {
  const weatherValue = WEATHER_TYPES.find(({ value }) => value === item.weather)?.value
  const now = new Date()
  const currentHour = now.getHours()
  const currentMinute = now.getMinutes()
  const value = {
    ...item,
    classification: item.classification || '',
    hour: typeof item.hour === 'undefined' ? currentHour : item.hour,
    minute: typeof item.minute === 'undefined' ? currentMinute : item.minute,
    weather: weatherValue,
    emojis: item.emojis || [],
    type: item.filename ? 'UPDATE' : 'CREATION',
  }
  return value
}

export default function FakeProfileItem({ item, dayIndex, profile, onCancel, onUpdated }) {
  const itemImagePath = useRef(item.path)
  const [editedItem, setEditedItem] = useState(formatEditedItemFromItem(item))
  const [editedImage, setEditedImage] = useState()
  const [editedImageData, setEditedImageData] = useState()
  const [loading, setLoading] = useState(false)

  const confirm = useConfirm()

  useEffect(() => {
    if (!editedImage) {
      setEditedImageData()
      return
    }
    const reader = new FileReader()
    reader.onload = (event) => {
      const imageUrl = event.target.result
      setEditedImageData(imageUrl)
    }
    reader.readAsDataURL(editedImage)
  }, [editedImage])

  const deleteImage = useCallback(async () => {
    if (!editedItem.path) {
      return
    }
    const confirmed = await confirm({
      description: 'This action cannot be reversed. Are you sure you want to delete this image?',
    })
      .then(() => true)
      .catch(() => false)

    if (!confirmed) {
      return
    }

    setLoading(true)
    try {
      const body = {
        path: editedItem.path,
        filename: editedItem.filename,
        username: editedItem.username,
        activityId: editedItem.activityId,
      }
      await api.post('/admin/fake-profiles/image/delete', body)
      onUpdated()
      onCancel()
    } catch (error) {
      console.error('Error while deleting fake profile image', error)
      confirm({
        title: 'Error while deleting image',
        description: error?.response?.data ? JSON.stringify(error?.response?.data) : null,
        hideCancelButton: true,
      })
    }
    setLoading(false)
  }, [editedItem, onUpdated, confirm, onCancel])

  const save = useCallback(async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('imgFile', editedImage)
      if (!editedItem.username) {
        formData.append('username', profile.username)
      }
      Object.keys(editedItem).forEach((key) => {
        if (Array.isArray(editedItem[key])) {
          editedItem[key].forEach((item) => {
            formData.append(key, item)
          })
        } else {
          if (typeof editedItem[key] !== 'undefined') {
            formData.append(key, editedItem[key])
          }
        }
      })

      await api.post('/admin/fake-profiles/image', formData)
      onUpdated()
      onCancel()
    } catch (error) {
      console.error('Error while sending fake profile data', error)
    }
    setLoading(false)
  }, [editedItem, editedImage, onUpdated, profile, onCancel])

  const imageUrl = useMemo(() => {
    if (editedImageData) {
      return editedImageData
    }
    if (!item.path) return null
    return `https://catchup-staging.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(item.path)}`
  }, [item, editedImageData])

  const isValid = useMemo(() => {
    return (
      !!imageUrl &&
      !!editedItem.classification &&
      typeof editedItem.hour !== 'undefined' &&
      typeof editedItem.minute !== 'undefined' &&
      !!editedItem.weather &&
      editedItem.emojis?.length > 0
    )
  }, [imageUrl, editedItem])

  useEffect(() => {
    if (item.path !== itemImagePath.current) {
      itemImagePath.current = item.path
      setEditedImage()
    }
    setEditedItem(formatEditedItemFromItem(item))
  }, [item])

  const handleChange = useCallback((fieldname, value) => {
    setEditedItem((prev) => ({
      ...prev,
      [fieldname]: value,
    }))
  }, [])

  return (
    <Container>
      <h2>{editedItem.type === 'UPDATE' ? 'Edition' : `Creation - day #${dayIndex}`}</h2>
      <FileSelect
        style={{
          backgroundImage: `url('${imageUrl}')`,
          border: imageUrl ? 'none' : '1px solid red',
        }}
        type="file"
        accept="image/*"
        onChange={(e) => {
          setEditedImage(e.target.files[0])
        }}
      />
      <input
        value={editedItem.classification}
        onChange={(e) => handleChange('classification', e.target.value)}
        placeholder="Classification"
        style={{
          color: editedItem.classification ? 'black' : 'red',
          borderColor: editedItem.classification ? 'black' : 'red',
        }}
      />
      <Row>
        <input
          value={editedItem.hour}
          min={0}
          max={23}
          type="number"
          onChange={(e) => handleChange('hour', e.target.value)}
        />
        <span>h</span>
        <input
          value={editedItem.minute}
          min={0}
          max={59}
          type="number"
          onChange={(e) => handleChange('minute', e.target.value)}
        />
      </Row>
      <select
        value={editedItem.weather}
        onChange={(e) => handleChange('weather', e.target.value)}
        style={{
          maxWidth: 200,
          color: editedItem.weather ? 'black' : 'red',
          borderColor: editedItem.weather ? 'black' : 'red',
        }}
      >
        <option selected={!editedItem.weather}>UNKOWN WEATHER</option>
        {WEATHER_TYPES.map((weather) => (
          <option key={weather.value} value={weather.value}>
            {weather.value} - {weather.label}
          </option>
        ))}
      </select>
      <EmojisSelector value={editedItem.emojis} onChange={(value) => handleChange('emojis', value)} />
      <Button variant="contained" color="primary" onClick={save} size="small" disabled={!isValid || loading}>
        Save
      </Button>
      <Button variant="contained" color="warning" onClick={onCancel} size="small" disabled={loading}>
        Cancel
      </Button>
      {editedItem.type === 'UPDATE' && (
        <Button variant="contained" color="error" onClick={deleteImage} size="small" disabled={loading}>
          Delete Image
        </Button>
      )}
    </Container>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  grid-gap: 12px;
`

const Container = styled.div`
  overflow-y: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  /* width: 220px; */
`

const Image = styled.div`
  display: flex;
  width: 150px;
  height: 200px;
  background-size: cover;
  border-radius: 12px;
  padding: 12px;
  position: relative;
  text-shadow: 0 0 4px rgba(0, 0, 0, 1), 2px 2px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.3);
`

const FileSelect = styled.input`
  width: 100px;
  height: 200px;
  cursor: pointer;
  width: 150px;
  height: 200px;
  background-size: cover;
  border-radius: 12px;
  padding: 12px;
  position: relative;
  text-shadow: 0 0 4px rgba(0, 0, 0, 1), 2px 2px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.3);
`
