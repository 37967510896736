import styled from 'styled-components'

export default function PromptTitle({ title, style, children, actions }) {
  return (
    <Container style={style}>
      <TitleContainer>
        <Title>{title}</Title>
        {actions ? <Actions>{actions}</Actions> : null}
      </TitleContainer>
      <Inner $paddingTop={actions ? 80 : 44}>{children}</Inner>
    </Container>
  )
}

const Actions = styled.div`
  background: rgba(0, 0, 0, 0.8);
  padding: 6px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  border-radius: 3px;
`

const TitleContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const Title = styled.div`
  background: black;
  border-radius: 4px;
  padding: 3px 12px;
  font-size: 10px;
  opacity: 0.8;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
`

const Inner = styled.div`
  flex: 1 1;
  overflow: auto;
  padding: 0;
  display: flex;
  flex-direction: column;

  textarea,
  > pre {
    padding-top: ${(props) => props.$paddingTop}px !important;
  }
`
