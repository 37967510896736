import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import SyntaxHighlighter from 'react-syntax-highlighter'
import dark from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark'
import styled from 'styled-components'

import PromptTitle from './PromptTitle'
import { MODELS } from './constants'
import { useStateContext } from '../../contexts/state.context'

export default function ImageClassificationTemplateEditor({
  name,
  style,
  value,
  onChange,
  extraData,
  response,
  model,
  onModelChange,
  sendImage,
  onSendImageChange,
}) {
  const { activity, tagsTree, tags, activityUser } = useStateContext()

  // const [promptTemplate, setPromptTemplate] = useState(value || '')
  const [promptResult, setPromptResult] = useState('')

  useEffect(() => {
    try {
      const template = window.ejs.compile(value, { client: true })
      const data = { activity, tags, tagsTree, user: activityUser }
      if (extraData) {
        Object.assign(data, extraData)
      }
      const resEjs = template(data)
      setPromptResult(resEjs)
    } catch (err) {
      setPromptResult('Invalid template')
    }
  }, [activity, tags, tagsTree, value, extraData, activityUser])

  return (
    <Container style={style}>
      <PromptTitle
        title={[name, 'Template'].join(' - ')}
        actions={
          <>
            <Select
              value={model}
              onChange={(e) => {
                onModelChange(e.target.value)
              }}
              size="small"
            >
              {Object.values(MODELS).map((modelName) => (
                <MenuItem key={modelName} value={modelName} size="small">
                  {modelName}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Switch
                  checked={sendImage}
                  onChange={() => {
                    onSendImageChange(!sendImage)
                  }}
                  size="small"
                />
              }
              label="Image"
              size="small"
            />
          </>
        }
        // style={{ width: '10%' }}
      >
        <PromptTextArea
          value={value || ''}
          onChange={(e) => {
            if (e.target.value !== value) {
              onChange(e.target.value)
            }
          }}
        />
      </PromptTitle>
      <PromptTitle title={[name, 'Result'].join(' - ')} style={{ width: '30px' }}>
        <PromptResultContainer>
          <PromptResult>{promptResult}</PromptResult>
          {response && (
            <Response>
              <h4 style={{ margin: 0, opacity: 0.2 }}>Response</h4>
              {JSON.stringify(response, null, 2)}
            </Response>
          )}
        </PromptResultContainer>
      </PromptTitle>
      {extraData && (
        <Draggable>
          <ExtraDataContainer>
            <ExtraTitle>Extra data</ExtraTitle>
            <ExtraDataContent>{JSON.stringify(extraData, null, 2)}</ExtraDataContent>
          </ExtraDataContainer>
        </Draggable>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1;
  position: relative;
`

const ExtraDataContainer = styled.div`
  position: absolute;
  bottom: 6px;
  left: 6px;
  opacity: 0.3;
  max-height: 100px;
  overflow: auto;
  max-width: calc(100% - 12px);

  border-radius: 3px;
  padding: 4px 6px;
  background: black;

  &:hover {
    opacity: 1;
  }
`

const ExtraTitle = styled.div`
  font-size: 8px;
  opacity: 0.5;
  color: white;
  font-family: monospace;
`

const ExtraDataContent = styled(SyntaxHighlighter).attrs({ style: dark, language: 'json', disabled: true })`
  font-size: 8px;
  margin: 0;
  background: transparent !important;
`

const PromptTextArea = styled.textarea`
  flex: 1 1;
  height: 100%;
  width: 100%;
  font-size: 11px;
  padding: 12px;
  background: rgb(52, 53, 65);
  color: rgb(236, 236, 241);
  font-family: monospace;
  border: none;
  border-right: 2px dashed rgba(255, 255, 255, 0.1);
  resize: none;
`

const PromptResultContainer = styled.div`
  flex: 1 1;
  width: 100%;
  background: rgb(52, 53, 65);
  padding-top: 28px;
`

const Response = styled.div`
  width: 100%;
  color: rgb(171, 227, 56);
  border: none;
  padding: 12px;
  font-size: 12px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell, 'Noto Sans',
    sans-serif, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`

const PromptResult = styled.div`
  width: 100%;
  color: rgb(236, 236, 241);
  border: none;
  padding: 12px;
  white-space: pre-wrap;
  font-size: 12px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell, 'Noto Sans',
    sans-serif, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`
