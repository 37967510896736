import { useEffect } from 'react'
import { JSONTree } from 'react-json-tree'
import styled from 'styled-components'

import { useStateContext } from '../../contexts/state.context'
import ActivityImage from '../ActivityImage'

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

export default function ImageClassificationDataViewer() {
  const { activity, tagsTree, tags, activityUser } = useStateContext()

  const data = { activity, tagsTree, tags, user: activityUser }

  return (
    <Container>
      {activity?.ActivityImage && <StyledActivityImage activity={activity} width="100%" height={150} />}
      <Inner>
        <JSONTree
          style={{ padding: 0, margin: 0 }}
          data={data}
          theme={{
            extend: theme,
          }}
          shouldExpandNodeInitially={(keypath, data, level) => {
            if (level < 1) {
              return true
            }

            if (keypath[keypath.length - 2] === 'user' || (level === 1 && keypath[0] === 'activity')) {
              return true
            }
          }}
        />
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  font-size: 10px;
  border-radius: 12px;
  /* flex: 1 1; */
  display: flex;
  flex-direction: column;
  margin: 4px;
  overflow: hidden;
  width: 300px;
`

const Inner = styled.div`
  flex: 1 1;
  overflow: auto;
  background: rgb(39, 40, 34);

  > ul {
    margin: 0 !important;
  }
`

const StyledActivityImage = styled(ActivityImage)`
  width: 100%;
`
