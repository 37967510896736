import { Autocomplete, Button, List, ListItem, ListItemText, TextField } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import ImageClassificationTag from './ImageClassificationTag'
import { useStateContext } from '../../contexts/state.context'
import api from '../../services/api'

export default function ImageClassificationTags({}) {
  const [creatingGlobalTag, setCreatingGlobalTag] = useState(false)
  const { orderedTags, tags, fetchTags: onChange } = useStateContext()

  const confirm = useConfirm()
  const alert = useCallback(
    (title) => {
      return confirm({ title, hideCancelButton: true })
    },
    [confirm]
  )

  const destroyGlobalTag = useCallback(
    async (tag) => {
      try {
        const confirmed = await confirm({
          description:
            // eslint-disable-next-line prettier/prettier
            'This tag will be destroyed and all related activities won\'t have a tag anymore, do you confirm?'
        })
          .then(() => true)
          .catch(() => false)

        if (confirmed) {
          await api.delete(`activities/classification/tags/${tag.id}`)
          confirm({ title: `Tag "${tag.name}" destroyed`, hideCancelButton: true })
        }
      } catch (err) {
        confirm({ title: 'Error while destroyig tag', hideCancelButton: true })
      }
      await onChange()
    },
    [onChange, confirm]
  )

  const [newTag, setNewTag] = useState('')
  const [newTagParent, setNewTagParent] = useState()
  const [search, setSearch] = useState('')

  const createGlobalTag = useCallback(async () => {
    try {
      setCreatingGlobalTag(true)
      await api.post('activities/classification/tags', { name: newTag })
      alert(`Tag "${newTag}" created`)
      setNewTag('')
      await onChange()
    } catch (err) {
      alert('Error while creating tag')
    }
    setCreatingGlobalTag(false)
  }, [newTag, onChange, alert])

  const patchGlobalTag = useCallback(
    async (tagId, patch) => {
      try {
        await api.patch(`activities/classification/tags/${tagId}`, patch)
        await onChange()
      } catch (err) {
        alert('Error while patching tag')
      }
    },
    [onChange, alert]
  )

  return (
    <Container>
      <Row>
        <TextField
          label="Search"
          onChange={(e) => {
            setSearch(e.target.value.trim().toLowerCase())
          }}
          size="small"
        />

        <div style={{ flex: '1 1' }} />
        <TextField
          style={{ marginRight: 12 }}
          label="Create new category"
          onChange={(e) => {
            setNewTag(e.target.value.trim().toLowerCase())
          }}
          disabled={creatingGlobalTag}
          value={newTag}
          size="small"
        />
        <Autocomplete
          style={{ width: 200 }}
          options={tags}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          onChange={(event, newValue) => {
            setNewTagParent(newValue)
          }}
          value={newTagParent ? tags.find((tag) => tag.id === newTagParent?.id) : null}
          renderInput={(params) => <TextField {...params} label="Parent" />}
          size="small"
        />

        <Button
          variant="contained"
          disabled={creatingGlobalTag || !newTag || tags.some((tag) => tag.name === newTag)}
          onClick={createGlobalTag}
        >
          Create
        </Button>
      </Row>
      <ListContainer>
        <CustomList dense>
          {orderedTags
            ?.filter((tag) => !search || tag.name.includes(search))
            .map((tag, index) => (
              <CustomListItem key={tag.id} style={{ backgroundColor: index % 2 ? 'rgba(0,0,0,0.1)' : undefined }}>
                <ImageClassificationTag
                  tag={tag}
                  tags={tags}
                  onDestroy={() => {
                    return destroyGlobalTag(tag)
                  }}
                  onSave={(value) => {
                    return patchGlobalTag(tag.id, value)
                  }}
                />
              </CustomListItem>
            ))}
        </CustomList>
      </ListContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ListContainer = styled.div`
  flex: 1 1;
  overflow-y: auto;
  max-height: calc(100vh - 90px);
`

const CustomList = styled(List)``

const CustomListItem = styled(ListItem)`
  &:hover {
    background-color: rgba(0, 255, 0, 0.1) !important;
  }
`
