/* eslint-disable max-len */
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { isEqual } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { PARAMETERS, TREE } from './AlgoPOVParametersConfig'
import AlgoPOVParametersSection from './AlgoPOVParametersSection'
import api from '../../services/api'

function getAlgoPOVParameters() {
  const defaultValue = Object.entries(PARAMETERS).reduce((acc, [key, val]) => {
    acc[key] = val.defaultValue
    return acc
  }, {})
  // const cache = localStorage.getItem('AlgoPOVParameters')
  // if (cache) {
  //   try {
  //     return { ...defaultValue, ...JSON.parse(cache) }
  //   } catch (err) {
  //     // Nothing to do
  //   }
  // }
  return { ...defaultValue }
}

export default function AlgoPOVParameters({ onChange, onSave, defaultValue }) {
  const [values, setValues] = useState(defaultValue)
  const [filter, setFilter] = useState('')
  const [changedValues, setChangedValues] = useState({})
  const serverValue = useRef(defaultValue)

  const confirm = useConfirm()

  const reinitValues = useCallback(async () => {
    const confirmed = await confirm({
      description: 'This action cannot be reversed. Are you sure you want to reinit values?',
    })
      .then(() => true)
      .catch(() => false)
    if (confirmed) {
      // localStorage.removeItem('AlgoPOVParameters')
      setValues(defaultValue)
    }
  }, [confirm, defaultValue])

  const timeoutRef = useRef()
  const initialized = useRef(false)

  const handleChange = useCallback((key, value) => {
    setChangedValues((prev) => {
      return {
        ...prev,
        [key]: { previous: serverValue.current?.[key], current: value },
      }
    })
    setValues((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }, [])

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      onChange(values)
    }
  }, [values, onChange])

  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      onChange(values)
    }, 500)
  }, [values, onChange])

  const hasChanged = useMemo(() => {
    return !isEqual(serverValue.current, values)
  }, [values])

  const handleSave = useCallback(async () => {
    const confirmed = await confirm({
      description: 'This action cannot be reversed. Are you sure you want to update server POV parameters?',
    })
      .then(() => true)
      .catch(() => false)
    if (confirmed) {
      onSave()
    }
  }, [onSave, confirm])

  return (
    <Container>
      <Actions>
        <TextField
          id="standard-basic"
          label="Search"
          variant="standard"
          size="small"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value)
          }}
        />
        <IconButton variant="contained" color="warning" onClick={reinitValues} size="small">
          <RestartAltIcon />
        </IconButton>
      </Actions>
      <Sections>
        {TREE.map((treeItem) => (
          <AlgoPOVParametersSection
            filter={filter}
            treeItem={treeItem}
            key={treeItem.key}
            values={values}
            handleChange={handleChange}
            changedValues={changedValues}
            isRoot
          />
        ))}
      </Sections>
      <Footer>
        <Button variant="contained" onClick={handleSave} size="small" disabled={!hasChanged}>
          Save
        </Button>
      </Footer>
    </Container>
  )
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`

const Container = styled.div`
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  /* background: black; */
  // padding: 12px;
  font-size: 12px;
  /* overflow-y: scroll; */
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Sections = styled.div`
  flex: 1 1;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.3);
`
