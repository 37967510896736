import { useMemo } from 'react'
import styled from 'styled-components'

import { computeUrl, mediaCDNTransformation } from '../hooks/useCDNSource/useCDNSource.utils'

export default function ActivityImage({ activity, width = 50, height = 50, style = {}, imageStyle = {}, ...rest }) {
  const config = useMemo(
    () => ({
      type: 'image',
      id: activity?.ActivityImage?.id,
      transformation: mediaCDNTransformation.mediaImage({ blurred: activity?.censored }),
    }),
    [activity?.ActivityImage?.id, activity?.censored]
  )

  const url = useMemo(() => {
    if (!activity.ActivityImage?.url) {
      return null
    }
    return computeUrl(config)
  }, [activity, config])

  return (
    <Container
      {...rest}
      style={{
        width,
        height,
        minWidth: width,
        minHeight: height,
        pointerEvents: 'none',
        backgroundImage: `url(${url})`,
        ...style,
      }}
    >
      {/* <Image src={url} style={imageStyle} /> */}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
`

const Image = styled.img`
  /* max-width: 100%; */
  /* max-height: 100%; */
  border: 1px solid yellow;
  /* border-radius: 3px; */
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
`
