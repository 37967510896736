import moment from 'moment'

function distanceSquared(a, b) {
  let sum = 0
  let n
  for (n = 0; n < a.length; n++) {
    sum += Math.pow(a[n] - b[n], 2)
  }
  return sum
}

export function computeCalendarDayId(date) {
  return parseInt(moment(date).format('YYYYMMDD'), 10)
}

export function computeEuclideanDistance(embedding1, embedding2) {
  return Math.sqrt(distanceSquared(embedding1, embedding2))
}

export function computeCosineSimilarity(vec1, vec2) {
  let dotProduct = 0
  let normVec1 = 0
  let normVec2 = 0

  if (vec1.length !== vec2.length) {
    throw new Error('[computeCosineSimilarity] Vectors must have the same dimension.')
  }

  for (let i = 0; i < vec1.length; i++) {
    dotProduct += vec1[i] * vec2[i] // Dot product calculation
    normVec1 += vec1[i] * vec1[i] // Calculation of the norm of vector vec1
    normVec2 += vec2[i] * vec2[i] // Calculation of the norm of vector vec2
  }

  normVec1 = Math.sqrt(normVec1) // Square root of the norm of vec1
  normVec2 = Math.sqrt(normVec2) // Square root of the norm of vec2

  if (normVec1 === 0 || normVec2 === 0) {
    throw new Error('[computeCosineSimilarity] Vectors must not be zero vectors.')
  }

  const cosineSimilarity = dotProduct / (normVec1 * normVec2)
  return cosineSimilarity
}

export function computeSimilarityDistance(a1, a2) {
  let imageDistance,
    textDistance,
    count = 0,
    totalDistance = 0
  if (a1.imageEmbeddings && a2.imageEmbeddings) {
    imageDistance = computeCosineSimilarity(a1.imageEmbeddings, a2.imageEmbeddings)
    totalDistance += imageDistance
    count++
  }
  if (a1.textEmbeddings && a2.textEmbeddings) {
    textDistance = computeCosineSimilarity(a1.textEmbeddings, a2.textEmbeddings)
    totalDistance += textDistance
    count++
  }

  return {
    text: textDistance,
    image: imageDistance,
    total: count ? totalDistance / count : null,
    isValid: typeof textDistance === 'number' || typeof imageDistance === 'number',
  }
}
