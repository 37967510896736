import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import MenuIcon from '@mui/icons-material/Menu'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { Box, IconButton, Stack } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'

export default function AppToolbar({ handleDrawerOpen, toggleActivities, showMenuIcon }) {
  return (
    <Toolbar variant="dense">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(!showMenuIcon && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ flex: '1 1' }} />
      <Stack direction="row" spacing={1}>
        <IconButton
          size="small"
          color="inherit"
          style={{ backgroundColor: '#1db954' }}
          onClick={() => {
            window.open(`${process.env.REACT_APP_API_URL}/auth/spotify/login`, '_blank', 'width=500,height=500')
          }}
        >
          <MusicNoteIcon />
        </IconButton>
        <IconButton size="small" onClick={toggleActivities} color="inherit">
          <ImageSearchIcon />
        </IconButton>
      </Stack>
    </Toolbar>
  )
}
