import { Autocomplete, Button, List, ListItem, TextField } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import api from '../../services/api'

export default function UserStream({ stream, tags, onChange }) {
  const [loading, setloading] = useState(false)

  const [name, setName] = useState(stream.name?.trim())
  const [streamTags, setStreamTags] = useState(stream.UserStreamTags?.map((ust) => ust.TagId) || [])

  useEffect(() => {
    setStreamTags(stream?.UserStreamTags?.map((ust) => ust.TagId) || [])
  }, [stream?.UserStreamTags])

  useEffect(() => {
    setName(stream?.name?.trim())
  }, [stream?.name])

  const confirm = useConfirm()
  const alert = useCallback(
    (title) => {
      return confirm({ title, hideCancelButton: true })
    },
    [confirm]
  )

  const updateStream = useCallback(async () => {
    setloading(true)
    try {
      const patch = {}

      if (name.trim() !== stream.name) {
        patch.name = name.trim()
      }

      if (
        streamTags.length !== stream.UserStreamTags?.length ||
        !streamTags.every((tagId) => stream.UserStreamTags?.some((ust) => ust.TagId === tagId))
      ) {
        patch.tags = streamTags
      }

      await api.patch(`activities/streams/${stream?.id}`, patch)
      await onChange()
      alert(`Stream "${name.trim()}" updated`)
      await onChange()
    } catch (err) {
      alert('Error while creating stream')
    }
    setloading(false)
  }, [name, stream?.name, stream?.UserStreamTags, stream?.id, streamTags, onChange, alert])

  const deleteStream = useCallback(async () => {
    setloading(true)
    try {
      const name = stream?.name
      await api.delete(`activities/streams/${stream?.id}`)
      await onChange()
      alert(`Stream "${name}" deleted`)
    } catch (err) {
      alert('Error while deleting stream')
    }
    setloading(false)
  }, [onChange, stream?.id, stream?.name, alert])

  const reset = useCallback(() => {
    setName(stream?.name?.trim())
    setStreamTags(stream?.UserStreamTags?.map((ust) => ust.TagId) || [])
  }, [stream])

  const isValid = name?.trim()?.length > 0 && streamTags?.length > 0

  const isPristine =
    name?.trim() === stream?.name &&
    streamTags.length === stream.UserStreamTags?.length &&
    streamTags.every((tagId) => stream.UserStreamTags?.some((ust) => ust.TagId === tagId))

  return (
    <ListItem key={stream.id} style={{ alignItems: 'flex-start' }}>
      <TextField
        style={{ marginRight: 12 }}
        label="Stream Name"
        onChange={(e) => {
          setName(e.target.value)
        }}
        disabled={loading}
        value={name}
        size="small"
      />
      <Autocomplete
        options={tags}
        multiple
        getOptionLabel={(option) => option?.name}
        filterSelectedOptions
        onChange={(event, newValue) => {
          setStreamTags(newValue.map((v) => v.id))
        }}
        value={streamTags.map((st) => tags.find((tag) => tag.id === st))}
        renderInput={(params) => <TextField {...params} label="tags" />}
        size="small"
        disablled={loading}
      />
      <div style={{ flex: '1 1', marginRight: 12 }} />
      <Button style={{ marginRight: 12 }} variant="outlined" onClick={reset} size="small" disabled={isPristine}>
        Reset
      </Button>
      <Button
        style={{ marginRight: 12 }}
        variant="contained"
        onClick={updateStream}
        size="small"
        disabled={isPristine || !isValid || loading}
      >
        Save
      </Button>
      <Button variant="contained" color="error" onClick={deleteStream} size="small" disabled={loading}>
        Delete
      </Button>
    </ListItem>
  )
}
