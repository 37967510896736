export function getToken() {
  return document.cookie && document.cookie.includes('token=') ? document.cookie.replace('token=', '') : null
}

export function setToken(value) {
  if (value) {
    const expiresIn = new Date()
    expiresIn.setTime(expiresIn.getTime() + 7 * 24 * 60 * 60 * 1000) // 1week hours from now
    let cookieValue = `token=${value}; expires=${expiresIn.toUTCString()}; `
    if (process.env.REACT_APP_COOKIE_DOMAIN) {
      cookieValue += `domain=${process.env.REACT_APP_COOKIE_DOMAIN};`
    }
    cookieValue += 'path=/'
    document.cookie = cookieValue
  } else {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
}
