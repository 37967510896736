import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../services/api'
import { getToken } from '../services/auth'

function Login({ onLogin }) {
  const [step, setStep] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState('+336')
  const [code, setCode] = useState('')
  const navigate = useNavigate()

  const confirm = useConfirm()
  const alert = useCallback(
    (title) => {
      return confirm({ title, hideCancelButton: true })
    },
    [confirm]
  )

  const validatePhone = useCallback(async () => {
    try {
      const res = await api.post('/auth/signin/request-code', { phoneNumber })
    } catch (e) {
      alert('Error')
      console.error(e)
    }
    setStep(1)
  }, [phoneNumber, alert])

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault()
      const res = await api.post('/auth/signin/verify-code', { phoneNumber, code })
      onLogin(res.data.token)
    },
    [phoneNumber, code, onLogin]
  )

  return (
    <div>
      <h1>Login</h1>
      {step === 0 && (
        <div id="step1">
          <label htmlFor="phone_number">Numéro de téléphone :</label>
          <input type="tel" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} required />
          <button onClick={validatePhone}>Suivant</button>
        </div>
      )}
      {step === 1 && (
        <div id="step2">
          <form onSubmit={submitForm}>
            <label htmlFor="verification_code">Code de vérification :</label>
            <input
              type="text"
              id="verification_code"
              pattern="\d{6}"
              maxLength="6"
              onChange={(e) => setCode(e.target.value)}
              value={code}
              required
            />
            <button type="submit">Se connecter</button>
          </form>
        </div>
      )}
    </div>
  )
}

export default Login
