import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  TextField,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useState } from 'react'

import UserStream from './UserStream'
import api from '../../services/api'

export default function UserStreams({ user, tags, style, onChange }) {
  const [loading, setloading] = useState(false)

  const [newStreamName, setNewStreamName] = useState('')
  const [newStreamTags, setNewStreamTags] = useState([])

  const confirm = useConfirm()
  const alert = useCallback(
    (title) => {
      return confirm({ title, hideCancelButton: true })
    },
    [confirm]
  )

  const handleChange = useCallback(() => onChange(user?.id), [onChange, user?.id])

  const addStream = useCallback(async () => {
    setloading(true)
    try {
      await api.post('activities/streams', {
        userId: user?.id,
        name: newStreamName.trim(),
        tags: newStreamTags,
      })
      alert(`Stream "${newStreamName}" created`)
      setNewStreamName('')
      setNewStreamTags([])
      await handleChange()
    } catch (err) {
      alert('Error while creating stream')
    }
    setloading(false)
  }, [newStreamName, newStreamTags, handleChange, user?.id, alert])

  const isValid = newStreamName?.trim()?.length > 0 && newStreamTags?.length > 0

  return (
    <Card style={style}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {user.name}
        </Typography>
        <List dense>
          {user.UserStreams?.map((stream, index) => (
            <UserStream key={stream.id} stream={stream} tags={tags} onChange={handleChange} />
          ))}
        </List>
        <Divider style={{ margin: '12px 0 18px' }} />
        <CardActions style={{ alignItems: 'flex-start' }}>
          <TextField
            style={{ marginRight: 12 }}
            label="New Stream Name"
            onChange={(e) => {
              setNewStreamName(e.target.value)
            }}
            disabled={loading}
            value={newStreamName}
            size="small"
          />
          <Autocomplete
            style={{ width: 200 }}
            options={tags}
            multiple
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            onChange={(event, newValue) => {
              setNewStreamTags(newValue.map((v) => v.id))
            }}
            value={newStreamTags.map((st) => tags.find((tag) => tag.id === st))}
            renderInput={(params) => <TextField {...params} label="tags" />}
            size="small"
            disablled={loading}
          />
          <div style={{ flex: '1 1' }} />
          <Button variant="contained" onClick={addStream} size="small" disabled={!isValid || loading}>
            Add stream
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )
}
