import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import styled from 'styled-components'

import ActivityImage from './ActivityImage'
import { useStateContext } from '../contexts/state.context'
import api from '../services/api'

export default function Activity({ activity, onChange }) {
  const { setActivity } = useStateContext()

  const [loading, setLoading] = useState(false)
  const confirm = useConfirm()

  const classify = useCallback(
    async ({ vision } = { vision: false }) => {
      if (loading) return
      setLoading(true)
      try {
        await api.post(`activities/${activity.id}/classify`, {
          vision,
        })
        await onChange()
      } catch (err) {
        confirm({ title: 'Error while classyfying', hideCancelButton: true })
      }
      setLoading(false)
    },
    [activity.id, loading, onChange, confirm]
  )

  return (
    <ActivityContainer key={activity.id}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Row>
          <ActivityImage activity={activity} />
          <Metadatas>
            {activity.description && (
              <Row>
                <Description style={{ opacity: 0.5, fontSize: 10 }}>{activity.description}</Description>
              </Row>
            )}
            {activity.classificationData?.steps?.version && <Tag>v{activity.classificationData?.steps?.version}</Tag>}
            {activity.classification && <Tag>{activity.classification}</Tag>}
          </Metadatas>
        </Row>
        <div style={{ flex: '1 1' }} />
        <IconButton
          size="small"
          color="primary"
          disabled={loading}
          onClick={() => {
            classify()
          }}
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            setActivity(activity)
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Row>
    </ActivityContainer>
  )
}

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
}

const Tag = styled.span`
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
  font-family: monospace;
  font-size: 10px;
  background: black;
  padding: 0 3px;
  border-radius: 3px;
  margin-left: 4px;
  text-align: center;
`

const ActivityContainer = styled.li`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 12px 0;
  font-size: 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Category = styled.div`
  font-weight: bold;
`

const Description = styled.div`
  /* font-style: italic; */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;

  b {
    opacity: 0.5;
    display: inline-block;
    width: 60px;
    font-family: monospace;
    font-size: 8px;
    white-space: nowrap;
    text-align: right;
    padding-right: 3px;
  }
`

const Metadatas = styled.div`
  margin-left: 12px;
`
