/* eslint-disable max-len */
import { ConfirmProvider } from 'material-ui-confirm'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'

import { StateProvider } from './contexts/state.context'
import Home from './pages/Home'
import Login from './pages/Login'
import api, { setToken as setApiToken } from './services/api'
import * as auth from './services/auth'

const validateTokenFormat = (token) => {
  const tokenRegex = /^(?:[\w-]*\.){2}[\w-]*$/
  return tokenRegex.test(token)
}

function App() {
  const [token, setToken] = useState(auth.getToken())
  const [loading, setLoading] = useState(!!token)
  const navigate = useNavigate()
  const [user, setUser] = useState()

  const fetchMe = useCallback(async () => {
    if (!token) return
    try {
      const res = await api.get('/auth/me')
      if (res?.data?.user) {
        setUser(res.data.user)
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }, [token])

  useEffect(() => {
    if (!token) {
      return
    }

    if (!validateTokenFormat(token)) {
      console.error('Invalid token')
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      if (window.location.pathname !== '/login') {
        navigate('/login')
      }
      return
    }

    fetchMe()
  }, [token, fetchMe, navigate])

  const handleToken = useCallback(
    async (value) => {
      auth.setToken(value)
      setToken(value)
      setApiToken(value)

      if (value) {
        if (window.location.pathname !== '/' && window.location.pathname !== '') {
          navigate('/')
        }
      }
    },
    [navigate]
  )

  const handledInitialToken = useRef(false)

  useEffect(() => {
    if (!handledInitialToken.current) {
      handledInitialToken.current = true
      if (!token) {
        if (window.location.pathname !== '/login') {
          navigate('/login')
        }
      }
    }
  }, [navigate, token])

  useEffect(() => {
    const savedToken = auth.getToken()
    if (savedToken === token) {
      return
    }
    if (savedToken) {
      handleToken(savedToken)
      if (window.location.pathname !== '/' && window.location.pathname !== '') {
        navigate('/')
      }
    } else {
      if (window.location.pathname !== '/login') {
        navigate('/login')
      }
    }
  }, [handleToken, navigate, token])

  return (
    <ConfirmProvider>
      {!loading && (
        <StateProvider user={user} fetchMe={fetchMe}>
          <Routes>
            <Route element={<Login onLogin={(value) => handleToken(value)} />} path="/login" />
            <Route element={<Home />} path="*" />
          </Routes>
        </StateProvider>
      )}
    </ConfirmProvider>
  )
}

export default App
