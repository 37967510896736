import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import AlgoFilters from './AlgoFilters/AlgoFilters'
import { AlgoPOV } from './AlgoPOV'
import FakeProfiles from './FakeProfiles/FakeProfiles'
import Interests from './Interests'
import ActivitiesSelector, {
  width as ActivitiesSelectorWidth,
} from '../components/ActivitiesSelector/ActivitiesSelector'
import AppMenu from '../components/AppMenu'
import AppToolbar from '../components/AppToolbar'
import ImageClassificationLlava from '../components/ImageClassification/ImageClassificationLlava'
import ImageClassificationTags from '../components/ImageClassification/ImageClassificationTags'
import Users from '../components/ImageClassification/Users'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'reduced' })(({ theme, reduced }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingTop: 48,
  // marginRight: 0,
  marginRight: `-${ActivitiesSelectorWidth}px`,
  ...(reduced && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    // marginRight: `-${ActivitiesSelectorWidth}px`,
  }),
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'reduced',
})(({ theme, reduced }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(reduced && {
    width: `calc(100% - ${ActivitiesSelectorWidth}px)`,
    marginRight: `${ActivitiesSelectorWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default function Home() {
  const theme = useTheme()
  const [showAppMenu, setShowAppMenu] = useState(false)
  const [showActivities, setShowActivities] = useState(localStorage.getItem('SHOW_ACTIVITIES') === 'true')

  useEffect(() => {
    localStorage.setItem('SHOW_ACTIVITIES', !!showActivities)
  }, [showActivities])

  const handleDrawerOpen = () => {
    setShowAppMenu(true)
  }

  const handleDrawerClose = () => {
    setShowAppMenu(false)
  }

  const location = useLocation()

  const prevPath = useRef(location?.pathname)
  useEffect(() => {
    if (location?.pathname !== prevPath.current) {
      prevPath.current = location?.pathname
      setShowAppMenu(false)
    }
  }, [location])

  const shouldShowActivities = showActivities && location.pathname === '/classification'

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
      <CssBaseline />
      <AppBar position="fixed" reduced={shouldShowActivities}>
        <AppToolbar
          handleDrawerOpen={handleDrawerOpen}
          toggleActivities={() => setShowActivities((prev) => !prev)}
          showMenuIcon={!showAppMenu}
        />
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="left"
        open={showAppMenu}
      >
        <DrawerHeader variant="dense">
          <IconButton onClick={handleDrawerClose} variant="dense">
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <AppMenu />
      </Drawer>

      <Main reduced={shouldShowActivities}>
        <Box sx={{ display: 'flex', flexDirection: 'vertical', height: '100%' }}>
          <Routes>
            <Route element={<Users />} path="/users" />
            <Route element={<AlgoPOV />} path="/algoPOV" />
            <Route element={<AlgoFilters />} path="/algo-filters" />
            <Route element={<Interests />} path="/interests" />
            <Route element={<ImageClassificationTags />} path="/categories" />
            <Route element={<FakeProfiles />} path="/fake-profiles" />
            <Route element={<ImageClassificationLlava />} path="/*" />
          </Routes>
        </Box>
      </Main>

      <Drawer
        sx={{
          width: ActivitiesSelectorWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: ActivitiesSelectorWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={shouldShowActivities}
        style={{ pointerEvents: shouldShowActivities ? undefined : 'none' }}
      >
        <ActivitiesSelector />
      </Drawer>
    </Box>
  )
}
