import { Button, LinearProgress } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useRef, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import dark from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import ImageClassificationDataViewer from './ImageClassificationDataViewer'
import ImageClassificationTemplateEditor from './ImageClassificationTemplateEditor'
import PromptTitle from './PromptTitle'
import { MODELS } from './constants'
import { useStateContext } from '../../contexts/state.context'
import api from '../../services/api'

export default function ImageClassificationLlava() {
  const [response, setResponse] = useState(false)
  const [loading, setLoading] = useState(false)
  const [stepsCount, setStepsCount] = useState(3)

  const [steps, setSteps] = useState(Array(stepsCount).fill({ model: MODELS.LLAVA, template: '', sendImage: false }))

  const { fetchActivities } = useStateContext()

  const confirm = useConfirm()

  const fetchTemplate = useCallback(async () => {
    const res = await api.get('/activities/classification/llava/prompts')
    const baseTemplate = Array(stepsCount).fill('')

    if (res.data?.steps && res.data.steps.length) {
      setSteps(
        res.data.steps.map((step) => ({
          model: step.model,
          template: step.template,
          sendImage: !!step.sendImage,
        }))
      )
    }
  }, [stepsCount])

  useEffect(() => {
    setLoading(true)
    async function fetch() {
      await fetchTemplate()
      setLoading(false)
    }
    fetch()
  }, [fetchTemplate])

  const { activity } = useStateContext()

  const prevActivity = useRef(activity?.id)

  useEffect(() => {
    if (prevActivity.current !== activity?.id) {
      setResponse(activity?.classificationData || null)
      prevActivity.current = activity?.id
    }
  }, [activity])

  const saveTemplates = useCallback(async () => {
    setLoading(true)
    try {
      const confirmed = await confirm({ description: 'This template will be used in production, do you confirm?' })
        .then(() => true)
        .catch(() => false)
      if (confirmed) {
        await api.post('activities/classification/llava/prompts', {
          steps,
        })
        confirm({ title: 'Prompt template saved', hideCancelButton: true })
      }
    } catch (err) {
      //Nothing to do
    }
    setLoading(false)
  }, [confirm, steps])

  const classify = useCallback(async () => {
    if (!activity?.id) return
    setLoading(true)
    try {
      // TODO: add steps
      const data = {
        tags: [],
        tagsTree: [],
        activity: {},
        user: {},
        results: steps.map(() => uuidv4()),
      }

      const dependencies = steps.map(() => [])
      for (let i = 0; i < steps.length; i++) {
        try {
          const template = window.ejs.compile(steps[i].template)
          const result = template(data)
          data.results.forEach((uniqueId, index) => {
            if (result.indexOf(uniqueId) !== -1) {
              dependencies[i].push(index)
            }
          })
        } catch (err) {
          console.error(err)
        }
      }

      const formattedSteps = steps.map((step, index) => ({
        template: step.template,
        sendImage: step.sendImage,
        model: step.model,
        index,
        dependencies: dependencies[index],
      }))
      const res = await api.post(`activities/${activity.id}/classify`, {
        steps: formattedSteps,
      })
      setResponse(res.data)
      await fetchActivities()
    } catch (err) {
      //Nothing to do
    }
    setLoading(false)
  }, [activity, fetchActivities, steps])

  const updateStepTemplate = useCallback((index, newTemplate) => {
    setSteps((prev) => {
      const value = [...prev]
      value[index].template = newTemplate
      return value
    })
  }, [])

  const updateStepModel = useCallback((index, newModel) => {
    setSteps((prev) => {
      const value = [...prev]
      value[index].model = newModel
      return value
    })
  }, [])

  const updateStepSendImage = useCallback((index, newValue) => {
    setSteps((prev) => {
      const value = [...prev]
      value[index].sendImage = newValue
      return value
    })
  }, [])

  const computeExtraData = useCallback(
    (index) => {
      const res = { results: [] }
      for (let i = 0; i < index; i++) {
        res.results.push(
          response?.results?.[i] || activity?.classificationData?.llava?.results?.[i] || `prompt #${i + 1} response`
        )
      }
      return res
    },
    [response, activity]
  )

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <LinearProgress />
        </LoadingContainer>
      )}
      <ImageClassificationDataViewer />
      <PromptsContainer>
        {steps?.map((_, index) => (
          <ImageClassificationTemplateEditor
            key={index}
            name={`step ${index + 1}`}
            onChange={(value) => {
              updateStepTemplate(index, value)
            }}
            onModelChange={(value) => {
              updateStepModel(index, value)
            }}
            onSendImageChange={(value) => {
              updateStepSendImage(index, value)
            }}
            value={steps[index].template}
            model={steps[index].model}
            sendImage={steps[index].sendImage}
            response={response?.results?.[index]}
            style={index > 0 ? { borderTop: '2px solid black' } : {}}
            extraData={computeExtraData(index)}
          />
        ))}
      </PromptsContainer>
      <PromptTitle title="Response" style={{ width: response ? 250 : 30 }}>
        <StyledSyntaxHighlighter language="json" style={dark} disabled>
          {response && JSON.stringify(response, null, 2)}
        </StyledSyntaxHighlighter>
      </PromptTitle>
      <Actions>
        <Button variant="contained" onClick={classify} size="small" disabled={!activity?.id || loading}>
          Classify
        </Button>

        <Button
          style={{ marginLeft: 6 }}
          variant="contained"
          color="error"
          onClick={saveTemplates}
          size="small"
          disabled={loading}
        >
          Save Templates
        </Button>
      </Actions>
    </Container>
  )
}

const TogglerContainer = styled.div``

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 10px;
  top: 0;
  z-index: 100;
  pointer-events: none;
`

const PromptsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  overflow: auto;
  flex: 3 1;
`

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  flex: 1 1;
  font-size: 10px;
  height: calc(100vh - 50px);
  margin: 0;
`

const Container = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: row;
  position: relative;
`

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  padding: 12px;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 1;
  }
`
