import { sortBy } from 'lodash'

export const SECTIONS = {
  GLOBAL: 'GLOBAL',

  INTERESTS: 'INTERESTS',
  EXTRAORDINARY: 'EXTRAORDINARY',
  MOMENT_BURST: 'MOMENT_BURST',
  NEW_LOCATION: 'NEW_LOCATION',
  FAR_FROM_HOME: 'FAR_FROM_HOME',

  EMOTION: 'EMOTION',
  PEOPLE_EMOTION: 'PEOPLE_EMOTION',
  MOOD: 'MOOD',

  HABIT: 'HABIT',
  HABIT_PLACE_TYPE: 'HABIT_PLACE_TYPE',
  HABIT_DELAY_BONUS: 'HABIT_DELAY_BONUS',

  AESTHETICS: 'AESTHETICS',

  SIMILARITY: 'SIMILARITY',

  ANALYZE: 'ANALYZE',

  OVERALL_SELECTION: 'OVERALL_SELECTION',
  TEMPORAL_DECAY_BONUS: 'TEMPORAL_DECAY_BONUS',
}

export const SECTIONS_CONFIG = {
  [SECTIONS.GLOBAL]: {
    title: 'Global',
  },
  [SECTIONS.EXTRAORDINARY]: {
    title: 'Extraordinary',
  },
  [SECTIONS.INTERESTS]: {
    title: 'Interests',
  },
  [SECTIONS.MOMENT_BURST]: {
    title: 'Moment Burst',
    description: 'Activity can be considered as extraordinary if a lot of photos has been taken at the same moment',
    parent: SECTIONS.EXTRAORDINARY,
  },
  [SECTIONS.NEW_LOCATION]: {
    title: 'New Location',
    description: 'Activity can be considered as extraordinary if its location is new',
    parent: SECTIONS.EXTRAORDINARY,
  },
  [SECTIONS.FAR_FROM_HOME]: {
    title: 'Home distance',
    description: 'Activity can be considered as extraordinary if far from home',
    parent: SECTIONS.EXTRAORDINARY,
  },
  [SECTIONS.EMOTION]: {
    title: 'Emotion',
  },
  [SECTIONS.PEOPLE_EMOTION]: {
    title: 'People Emotion',
    parent: SECTIONS.EMOTION,
  },
  [SECTIONS.MOOD]: {
    title: 'Mood',
    parent: SECTIONS.EMOTION,
  },
  [SECTIONS.HABIT]: {
    title: 'Habit',
    description: 'Scoring related to user habits',
  },
  [SECTIONS.HABIT_PLACE_TYPE]: {
    title: 'Place type',
    description: 'HOME, HABIT, SECOND_PLACE,...',
    parent: SECTIONS.HABIT,
  },
  [SECTIONS.HABIT_DELAY_BONUS]: {
    title: 'Habit - Delay Bonus',
    description:
      'To avoid pushing photos related to habits too frequently, a score is calculated based on the distance from the last photo of the same habit type',
    parent: SECTIONS.HABIT,
  },
  [SECTIONS.AESTHETICS]: {
    title: 'Aesthetics',
  },
  [SECTIONS.SIMILARITY]: {
    title: 'Similarity',
    description:
      'Allows deduplication of images that are too close with a penalty that decays based on the time distance from the last published or from the same group',
  },
  [SECTIONS.ANALYZE]: {
    title: 'Analyze',
  },
  [SECTIONS.OVERALL_SELECTION]: {
    title: 'Algo config',
  },
  [SECTIONS.TEMPORAL_DECAY_BONUS]: {
    title: 'Temporal Decay Bonus',
    description:
      'During selection, a minimum score is applied as a filter to avoid pushing everything. This score depends on the median of the published activity scores. A decreasing multiplier based on time is applied to this minimum score',
    parent: SECTIONS.OVERALL_SELECTION,
  },
}

export const PARAMETERS = {
  firstMinScorePercentPicked: {
    defaultValue: 0.1,
    max: 1,
    min: 0,
    step: 0.05,

    title: 'First Minimum Score Percent Picked',
    description: 'The minimum percentage of the highest scores to be considered for the first minimum score',
    impact: 'Affects the threshold for selecting the top scores for further processing',
    section: SECTIONS.GLOBAL,
  },

  /**
   * computeSimilarityScore
   */
  similarityMalusMaxDiffThresholdHours: {
    title: 'Max Diff Threshold Hours',
    defaultValue: 24,
    min: 1,
    max: 96,
    unit: 'hours',
    section: SECTIONS.SIMILARITY,
    impact: 'Determines the maximum difference in hours between two activities in order to be considered similar',
  },
  similarityImageThreshold: {
    title: 'Image Threshold',
    defaultValue: 0.8,
    min: 0,
    max: 1,
    step: 0.01,
    section: SECTIONS.SIMILARITY,
    impact: 'Determines the minimum image cosine similarity value over which two activities are considered as similar',
    description:
      'Determines the minimum image cosine similarity value over which two activities are considered as similar',
  },
  totalSimilarityImageWeight: {
    title: 'Total Image Weight',
    defaultValue: 1,
    min: 1,
    max: 60,
    section: SECTIONS.SIMILARITY,
  },
  similarityTextThreshold: {
    title: 'Text Threshold',
    defaultValue: 0.8,
    min: 0,
    max: 1,
    step: 0.01,
    section: SECTIONS.SIMILARITY,
    impact: 'Determines the minimum text cosine similarity value over which two activities are considered as similar',
    description:
      'Determines the minimum text cosine similarity value over which two activities are considered as similar',
  },
  totalSimilarityTextWeight: {
    title: 'Total Text Weight',
    defaultValue: 1,
    min: 1,
    max: 10,
    section: SECTIONS.SIMILARITY,
  },

  /**
   * Analyze malus word
   */
  MalusListWordMalus: {
    title: 'Word malus',
    defaultValue: 30,
    min: 1,
    max: 80,
    section: SECTIONS.ANALYZE,
    description:
      'If a word from the list is detected in the analysis or image classification, a penalty is applied to the score.',
    impact:
      'If a word from the list is detected in the analysis or image classification, a penalty is applied to the score.',
  },

  /**
   * computeInterestsScore
   */
  interestFrequentDaysCoveredThreshold: {
    title: 'Days covered threshold',
    defaultValue: 7,
    min: 1,
    max: 100,
    unit: 'days',
    section: SECTIONS.INTERESTS,
    impact: 'Determines the minimum number of days an interest must cover to be considered frequent',
  },
  interestFrequentWeekFrequencyThreshold: {
    title: 'Weekly frequency threshold',
    defaultValue: 1,
    min: 1,
    max: 30,
    unit: '/ week',
    section: SECTIONS.INTERESTS,
    impact: 'Determines the minimum frequency per week for an interest to be considered frequent',
  },
  interestHabitBaseScore: {
    title: 'Frequent interest base score',
    defaultValue: 30,
    min: 1,
    max: 100,
    section: SECTIONS.INTERESTS,
    impact: 'Base score for a frequent interest',
  },
  interestNewBaseScore: {
    title: 'New interest base score',
    defaultValue: 10,
    min: 1,
    max: 100,
    section: SECTIONS.INTERESTS,
    impact: 'Base score for a new interest',
  },

  /**
   * computeExtraordinaryScore
   */
  momentBurstMinCount: {
    defaultValue: 1,
    min: 1,
    max: 30,

    section: SECTIONS.MOMENT_BURST,
    title: 'Moment Burst Minimum Count',
    unit: 'photos',
    description: 'The minimum number of consecutive photos required to trigger a moment burst',
    impact: 'Influences the determination of a moment burst based on the number of consecutive photos',
  },
  momentBurstScoreMultiplier: {
    defaultValue: 2,
    min: 1,
    max: 50,

    section: SECTIONS.MOMENT_BURST,
    title: 'Moment Burst Score Multiplier',
    description: 'The multiplier applied to the score of a moment burst',
    impact: 'Adjusts the significance of a moment burst in the overall scoring',
  },
  momentBurstMaxScore: {
    defaultValue: 30,
    min: 1,
    max: 100,

    section: SECTIONS.MOMENT_BURST,
    title: 'Moment Burst Max Score',
    description: 'The multiplier applied to the score of a moment burst',
    impact: 'Burst score cannot be higher',
  },
  newLocationScore: {
    defaultValue: 20,
    min: 1,
    max: 100,

    section: SECTIONS.NEW_LOCATION,
    title: 'New Location Score',
    description: 'The score assigned to a new location',
    impact: 'Determines the weight of a new location in the scoring process',
  },

  /**
   * computeExtraordinaryScore - farFromHome
   */
  farFromHomeValue: {
    defaultValue: 50,
    min: 1,
    max: 1000,

    section: SECTIONS.FAR_FROM_HOME,
    title: 'Far From Home Value',
    unit: 'km',
    description: 'The distance threshold considered as "far from home"',
    impact: "Does not Influences the scoring based on the distance from the user's home if below",
  },
  distanceFromHomeClose: {
    defaultValue: 200,
    max: 1000,
    min: 0,
    section: SECTIONS.FAR_FROM_HOME,
    title: 'Distance From Home Close',
    unit: 'km',
    description: 'The distance threshold considered as "close to home"',
    impact: "Affects the scoring based on the proximity to the user's home",
  },
  distanceFromHomeCloseScore: {
    defaultValue: 10,
    max: 1000,
    min: 0,
    section: SECTIONS.FAR_FROM_HOME,
    title: 'Distance From Home Close Score',
    description: 'The score assigned to a location close to home',
    impact: "Determines the weight of a location close to the user's home in the scoring process",
  },

  distanceFromHomeFar: {
    defaultValue: 500,
    max: 1000,
    min: 0,
    section: SECTIONS.FAR_FROM_HOME,
    title: 'Distance From Home Far',
    unit: 'km',
    description: 'The distance threshold considered as "far from home"',
    impact: "Influences the scoring based on the distance from the user's home",
  },
  distanceFromHomeFarScore: {
    defaultValue: 20,
    max: 1000,
    min: 0,
    section: SECTIONS.FAR_FROM_HOME,
    title: 'Distance From Home Far Score',
    description: 'The score assigned to a location far from home',
    impact: "Determines the weight of a location far from the user's home in the scoring process",
  },
  distanceFromHomeVeryFarScore: {
    defaultValue: 1000,
    max: 3000,
    min: 0,
    section: SECTIONS.FAR_FROM_HOME,
    title: 'Distance From Home Very Far Score',
    unit: 'km',
    description: 'The score assigned to a location very far from home',
    impact: "Determines the weight of a location very far from the user's home in the scoring process",
  },

  /**
   * computeEmotionScore
   */
  facesScoreMultiplier: {
    defaultValue: 5,
    max: 30,
    min: 0,
    section: SECTIONS.EMOTION,
    title: 'Faces Score Multiplier',
    description: 'The multiplier applied to the score based on detected faces',
    impact: 'Adjusts the significance of the presence of faces in the overall scoring',
  },
  achievementScore: {
    defaultValue: 10,
    max: 100,
    min: 0,
    section: SECTIONS.EMOTION,
    title: 'Achievement Score',
    description: 'The score assigned to an achievement',
    impact: 'Determines the weight of an achievement in the scoring process',
  },
  peopleEmotionNegativeScore: {
    defaultValue: -10,
    max: 100,
    min: -100,
    section: SECTIONS.PEOPLE_EMOTION,
    title: '"negative" score',
    description: 'The score added if emotion is negative',
    impact: 'Determines the weight of a "negative" emotion in the scoring process',
  },
  peopleEmotionPositiveScore: {
    defaultValue: 10,
    max: 100,
    min: 0,
    section: SECTIONS.PEOPLE_EMOTION,
    title: '"positive" score',
    description: 'The score added if emotion is positive',
    impact: 'Determines the weight of a "positive" emotion in the scoring process',
  },
  peopleEmotionEcstaticScore: {
    defaultValue: 30,
    max: 100,
    min: 0,
    section: SECTIONS.PEOPLE_EMOTION,
    title: '"ecstatic" score',
    description: 'The score added if emotion is ecstatic',
    impact: 'Determines the weight of a "ecstatic" emotion in the scoring process',
  },

  cozyMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Cozy" Score',
    description: 'The score assigned to a cozy mood',
    impact: 'Determines the weight of a cozy mood in the scoring process',
  },
  busyMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Busy" Score',
    description: 'The score assigned to a busy mood',
    impact: 'Determines the weight of a busy mood in the scoring process',
  },
  joyfulMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Joyful" Score',
    description: 'The score assigned to a joyful mood',
    impact: 'Determines the weight of a joyful mood in the scoring process',
  },
  peacefulMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Peaceful" Score',
    description: 'The score assigned to a peaceful mood',
    impact: 'Determines the weight of a peaceful mood in the scoring process',
  },
  romanticMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Romantic" Score',
    description: 'The score assigned to a romantic mood',
    impact: 'Determines the weight of a romantic mood in the scoring process',
  },
  intriguingMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Intriguing" Score',
    description: 'The score assigned to an intriguing mood',
    impact: 'Determines the weight of an intriguing mood in the scoring process',
  },
  tenseMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Tense" Score',
    description: 'The score assigned to a tense mood',
    impact: 'Determines the weight of a tense mood in the scoring process',
  },
  formalMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Formal" Score',
    description: 'The score assigned to a formal mood',
    impact: 'Determines the weight of a formal mood in the scoring process',
  },
  casualMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Casual" Score',
    description: 'The score assigned to a casual mood',
    impact: 'Determines the weight of a casual mood in the scoring process',
  },
  inspiringMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Inspiring" Score',
    description: 'The score assigned to an inspiring mood',
    impact: 'Determines the weight of an inspiring mood in the scoring process',
  },
  abstractMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Abstract" Score',
    description: 'The score assigned to an abstract mood',
    impact: 'Determines the weight of an abstract mood in the scoring process',
  },
  funMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Fun" Score',
    description: 'The score assigned to a fun mood',
    impact: 'Determines the weight of a fun mood in the scoring process',
  },
  nostalgicMoodScore: {
    defaultValue: 0,
    max: 100,
    min: -100,
    section: SECTIONS.MOOD,
    title: '"Nostalgic" Score',
    description: 'The score assigned to a nostalgic mood',
    impact: 'Determines the weight of a nostalgic mood in the scoring process',
  },

  /**
   * computeHabitScore
   */
  placeTypeSecondPlaceScore: {
    defaultValue: 10,
    max: 50,
    min: 0,
    section: SECTIONS.HABIT_PLACE_TYPE,
    title: 'Place Type Second Place Score',
    description: 'The score assigned to a location of the second most visited place type',
    impact: 'Determines the weight of the second most visited place type in the scoring process',
  },
  placeTypeHabitScore: {
    defaultValue: 5,
    max: 50,
    min: 0,
    section: SECTIONS.HABIT_PLACE_TYPE,
    title: 'Place Type Habit Score',
    description: 'The score assigned to a location of the most visited place type',
    impact: 'Determines the weight of the most visited place type in the scoring process',
  },
  placeTypeHomeScore: {
    defaultValue: 20,
    max: 50,
    min: 0,
    section: SECTIONS.HABIT_PLACE_TYPE,
    title: 'Place Type Home Score',
    description: 'The score assigned to a location of the home place type',
    impact: 'Determines the weight of the home place type in the scoring process',
  },
  habitLastPublishedMinDiffHours: {
    defaultValue: 24,
    max: 100,
    min: 0,
    section: SECTIONS.HABIT_DELAY_BONUS,
    title: 'delay threshold',
    unit: 'hours',
    description: 'The minimum time difference for a habit to be considered as recently published',
    impact: 'Duration in hours since the last publish, after which a habit place of the same type will gain score',
  },
  habitLastPublishedMultiplierMax: {
    defaultValue: 7,
    max: 50,
    min: 0,
    section: SECTIONS.HABIT_DELAY_BONUS,
    title: 'max occurences',
    description: 'The maximum multiplier applied to the score based on the recency of habit publication',
    impact: 'Max of the score multiplier',
  },
  habitLastPublishedMultiplierMin: {
    defaultValue: 0,
    max: 50,
    min: 0,
    section: SECTIONS.HABIT_DELAY_BONUS,
    title: 'min occurences',
    description: 'The minimum multiplier applied to the score based on the recency of habit publication',
    impact: 'Min of the score multiplier',
  },
  habitLastPublishedDiffDivider: {
    defaultValue: 24,
    max: 100,
    min: 0,
    section: SECTIONS.HABIT_DELAY_BONUS,
    title: 'hours / occurence',
    description: 'The divider used to calculate the recency score based on habit publication',
    unit: 'hours',
    impact: 'The higher the value, the greater the time gap between two occurrences that allows the score to increase',
  },
  habitLastPublishedHomeScoreMultiplier: {
    defaultValue: 1,
    max: 50,
    min: 0,
    section: SECTIONS.HABIT_DELAY_BONUS,
    title: '"HOME" place base score',
    description: 'The multiplier applied to the score based on the recency of habit publication at home',
    impact: 'Base score for a "HOME" place type',
  },
  habitLastPublishedNotHomeScoreMultiplier: {
    defaultValue: 5,
    max: 50,
    min: 0,
    section: SECTIONS.HABIT_DELAY_BONUS,
    title: 'default habit place base score',
    description: 'The multiplier applied to the score based on the recency of habit publication away from home',
    impact: 'Base score for a habit place type that is not "HOME"',
  },

  /**
   * computeAestheticsScore
   */
  aestheticsMin: {
    defaultValue: 5,
    max: 10,
    min: 0,
    section: SECTIONS.AESTHETICS,
    title: 'Aesthetics Minimum',
    description: 'The minimum aesthetics score threshold',
    impact: 'Affects the minimum aesthetics score required for a location to be considered in the scoring process',
  },
  aestheticsScoreMultiplier: {
    defaultValue: 2,
    max: 50,
    min: 0,
    section: SECTIONS.AESTHETICS,
    title: 'Aesthetics Score Multiplier',
    description: 'The multiplier applied to the aesthetics score of a location',
    impact: 'Adjusts the significance of aesthetics in the overall scoring: final score = aesthetics score * value',
  },

  /**
   * SELECTION
   */
  algoMinutesDelay: {
    defaultValue: 180,
    max: 500,
    min: 0,
    section: SECTIONS.OVERALL_SELECTION,
    title: 'Algorithm Processing Delay',
    unit: 'mins',
    description: 'The delay in minutes before the algorithm processing is triggered',
    impact:
      'A higher value reduces the frequency of algorithm processing and increases the size of photo groups processed by the algorithm',
  },

  temporalBonusDecayHistoryMaxDiffInDays: {
    defaultValue: 2,
    max: 30,
    min: 1,
    section: SECTIONS.TEMPORAL_DECAY_BONUS,
    title: 'Median computation window',
    unit: 'days',
    impact: 'Median will be computed on all published activity created in the last %value% days',
  },
  temporalBonusDecayHoursPerOccurence: {
    defaultValue: 24,
    max: 24 * 4,
    min: 1,
    section: SECTIONS.TEMPORAL_DECAY_BONUS,
    title: 'hours / occurence',
    unit: 'hours',
    description:
      'Defines the rate at which the score required for a photo to be selected decreases over time between publications.',
    impact:
      'The higher the value, the greater the time gap between two occurrences that allows the minimum score to decrease',
  },
  temporalBonusDecayPercentPerOccurence: {
    defaultValue: 0.1,
    max: 1,
    min: 0,
    step: 0.05,
    section: SECTIONS.TEMPORAL_DECAY_BONUS,
    title: 'percent / occurence',
    description:
      'Defines how much the score required for a photo to be selected decreases between publications for each occurence .',
    impact: 'The higher the value, the more the score per occurrence required to be selected decreases',
  },
  temporalBonusDecayMinPercent: {
    defaultValue: 0.6,
    max: 1,
    min: 0,
    step: 0.05,
    section: SECTIONS.TEMPORAL_DECAY_BONUS,
    title: 'min percent',
    description: 'The minimum value for the temporal bonus decay multiplier',
    impact: 'The higher this value, the higher the score of the photo must be to be selected',
  },
  temporalBonusDecayMaxPercent: {
    defaultValue: 1,
    max: 1,
    min: 0,
    step: 0.05,
    section: SECTIONS.TEMPORAL_DECAY_BONUS,
    title: 'max percent',
    description: 'The maximum value for the temporal bonus decay multiplier',
    impact: 'The higher this value, the higher the score of the photo must be to be selected',
  },
}

const orderedSections = sortBy(SECTIONS, (name) => (SECTIONS_CONFIG[name]?.parent ? 1 : 0))

function buildSectionTree(parentName, sections) {
  const children = sections
    .filter((name) => SECTIONS_CONFIG[name]?.parent === parentName)
    .map((name) => buildSectionTree(name, sections))

  const parameters = Object.entries(PARAMETERS)
    .filter(([key, value]) => {
      return value.section === parentName
    })
    .map(([key, value]) => {
      return {
        ...value,
        key,
      }
    })

  const dict = {
    key: parentName,
    title: SECTIONS_CONFIG[parentName]?.title,
    description: SECTIONS_CONFIG[parentName]?.description,
  }

  if (children?.length) {
    dict.children = children
  }

  if (parameters?.length) {
    dict.parameters = parameters
  }

  return dict
}

const COMPUTED_TREE = orderedSections.reduce((acc, sectionName) => {
  if (!SECTIONS_CONFIG[sectionName]?.parent) {
    acc[sectionName] = buildSectionTree(sectionName, orderedSections)
  }
  return acc
}, {})

const sectionsValues = Object.values(SECTIONS)
export const TREE = sortBy(Object.values(COMPUTED_TREE), ({ key }) => sectionsValues.indexOf(key))
