/* eslint-disable react/no-unescaped-entities */
import { Autocomplete, Button, TextField } from '@mui/material'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

export default function ImageClassificationTag({ tags, tag, onSave, onDestroy }) {
  const [name, setName] = useState(tag.name)
  const [description, setDescription] = useState(tag.description)
  const [newTagParent, setNewTagParent] = useState(tags.find((_tag) => _tag.id === tag.TagId))

  const [loading, setLoading] = useState(false)

  const errorMessage = useMemo(() => {
    if (!tag.valid) {
      return "Invalid category - won't be used - it has to be a related to a group (child or parent)"
    }

    if (!!tag.TagId && !newTagParent) {
      return 'Invalid category - unkown parent'
    }

    return null
  }, [tag, newTagParent])

  return (
    <Container style={{ marginLeft: newTagParent ? 24 : 0 }}>
      <TextField
        style={{ marginRight: 12 }}
        label="name"
        onChange={(e) => {
          setName(e.target.value?.trim().toLowerCase())
        }}
        value={name}
        size="small"
      />
      <TextField
        style={{ marginRight: 12 }}
        label="description"
        onChange={(e) => {
          setDescription(e.target.value)
        }}
        value={description}
        size="small"
      />
      <Autocomplete
        style={{ width: 200 }}
        options={tags}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        onChange={(event, newValue) => {
          setNewTagParent(newValue)
        }}
        value={newTagParent ? tags.find((tag) => tag.id === newTagParent?.id) : null}
        renderInput={(params) => <TextField {...params} label="Parent" />}
        size="small"
      />

      {errorMessage ? <InvalidMessage>{errorMessage}</InvalidMessage> : <div style={{ flex: 1 }} />}
      <Button
        disabled={loading}
        variant="outlined"
        color="error"
        size="small"
        onClick={async () => {
          setLoading(true)
          await onDestroy()
          setLoading(false)
        }}
      >
        Delete
      </Button>

      <Button
        style={{ marginLeft: 12 }}
        disabled={
          loading ||
          (name === tag?.name &&
            description === tag?.description &&
            (tag?.TagId === newTagParent?.id || (!tag?.TagId && !newTagParent)))
        }
        variant="contained"
        size="small"
        onClick={async () => {
          setLoading(true)
          const patch = {}
          if (name !== tag.name) {
            patch.name = name
          }

          if (description !== tag.description) {
            patch.description = description
          }

          if (newTagParent && newTagParent.id !== tag.TagId) {
            patch.parentId = newTagParent.id
          }

          if (Object.keys(patch).length) {
            await onSave(patch)
          }
          setLoading(false)
        }}
      >
        Save
      </Button>
    </Container>
  )
}

const InvalidMessage = styled.div`
  flex: 1 1;
  justify-content: center;
  color: red;
  font-size: 10px;
  text-align: center;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
`
