import { Button, LinearProgress } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import ActivityImage from '../components/ActivityImage'
import { useStateContext } from '../contexts/state.context'
import api from '../services/api'

export default function Interests() {
  const { tags } = useStateContext()
  const [existingTags, setExistingTags] = useState([])
  const [activeTag, setActiveTag] = useState(null)

  const dataRef = useRef()

  const fetch = useCallback(async () => {
    const res = await api.get('/activities/interests')
    dataRef.current = res.data.activitiesIdByTagId
    setExistingTags(
      Object.entries(res.data.activitiesIdByTagId).map(([key, value]) => ({
        id: key,
        count: value.length,
      }))
    )
  }, [])

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <Container>
      UserTags yo
      {existingTags.map(({ id, count }) => (
        <div key={id}>
          <h3>
            {tags?.find((t) => t.id === id)?.name || id} ({count})
            {/* <Button variant="contained" onClick={() => setActiveTag(id)} size="small">
              Select
            </Button> */}
          </h3>
          <ImageContainer>
            {(true || activeTag === id) &&
              dataRef.current[id]
                ?.slice(0, 100)
                .map(({ ActivityId, ActivityImageId, ActivityImageUrl }, index) => (
                  <span key={ActivityId}>
                    {ActivityImageId && (
                      <ActivityImage
                        activity={{ id: ActivityId, ActivityImage: { id: ActivityImageId, url: ActivityImageUrl } }}
                        width={100}
                        height={100}
                      />
                    )}
                  </span>
                ))}
          </ImageContainer>
        </div>
      ))}
    </Container>
  )
}

const Container = styled.div`
  padding: 12px;
`

const ImageContainer = styled.div`
  grid-gap: 12px;
  display: flex;
  flex-wrap: wrap;
  > span {
    display: inline-block;
  }
`
