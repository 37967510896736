import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { sortBy } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import ActivityImage from '../../components/ActivityImage'
import { useStateContext } from '../../contexts/state.context'

const IMAGE_SIZE = 120
const GRID_GAP = 10

export default function AlgoPOVActivities({
  userId,
  setSelectedActivity,
  scoringConfig,
  selectedActivity,
  scoringSimulation,
  simulateScoring,
}) {
  const { activities } = useStateContext()
  const [moments, setMoments] = useState([])

  const [calendarDays, setCalendarDays] = useState([])

  // const [activitiesIds, setActivitiesIds] = useState([])
  // const [selectedActivitiesId, setSelectedActivitiesId] = useState([])
  const [groupByActivity, setGroupByActivity] = useState({})

  const previousUserId = useRef(userId)
  const previousActivitiesId = useRef([])
  const updatedList = useRef(false)

  /** POV SELECTION */

  const updateSelectedActivities = useCallback(
    (activities) => {
      const newGroupByActivity = {}

      let lastDate
      let groupIndex = -1
      const groups = []

      const sortedByCreatedAt = sortBy(activities, 'creationDate')
      for (let i = 0; i < sortedByCreatedAt.length; i++) {
        const activity = sortedByCreatedAt[i]
        const mDate = moment(activity.creationDate)

        if (!lastDate || mDate.diff(lastDate) > scoringConfig.algoMinutesDelay * 60 * 1000) {
          lastDate = mDate
          groupIndex++
          groups[groupIndex] = []
        }

        newGroupByActivity[activity.id] = groupIndex
        groups[groupIndex].push(activity)
      }

      setGroupByActivity(newGroupByActivity)
    },
    [scoringConfig?.algoMinutesDelay]
  )

  /** SCORES */

  // const updateScores = useCallback(() => {
  //   if (!activities.length) return
  //   const sortedActivities = sortBy(activities, 'creationDate')
  //   const newScores = {}
  //   for (let i = 0; i < sortedActivities.length; i++) {
  //     const activity = sortedActivities[i]
  //     newScores[activity.id] = computeActivityScore(activity, usersClassificationData[activity.UserId])
  //   }
  //   setScores(newScores)
  //   return newScores
  // }, [activities, usersClassificationData])

  /** MOMENTS */

  const updateActivities = useCallback(() => {
    const activitiesByCalendarDayId = activities.reduce((acc, activity) => {
      if (activity.UserId !== userId || !activity.calendarDayId) return acc
      if (!acc[activity.calendarDayId]) {
        acc[activity.calendarDayId] = []
      }
      acc[activity.calendarDayId].push(activity)
      return acc
    }, {})

    setCalendarDays(
      Object.values(activitiesByCalendarDayId).sort((a, b) => b?.[0]?.calendarDayId - a?.[0]?.calendarDayId)
    )

    const groupedActivities = activities.reduce((grouped, activity) => {
      if (activity.UserId !== userId || !activity.PlaceMomentId) return grouped
      const placeMomentId = activity.PlaceMomentId
      if (!grouped[placeMomentId]) {
        grouped[placeMomentId] = []
      }
      grouped[placeMomentId].push(activity)
      return grouped
    }, {})
    setMoments(Object.values(groupedActivities))
  }, [activities, userId])

  useEffect(() => {
    updatedList.current = false
  }, [scoringConfig])

  useEffect(() => {
    if (userId !== previousUserId) {
      previousActivitiesId.current = []
      updatedList.current = false
    }

    if (updatedList.current || !scoringConfig) {
      return
    }

    const userActivities = activities.filter((activity) => activity.UserId === userId && !!activity.PlaceMomentId)

    let shouldUpdate = userActivities.length !== previousActivitiesId.current.length || userId !== previousUserId
    if (!shouldUpdate) {
      const newActivitiesId = sortBy(userActivities, 'id').map((activity) => activity.id)
      if (newActivitiesId.some((id, index) => previousActivitiesId.current[index] !== id)) {
        shouldUpdate = true
        previousActivitiesId.current = [...newActivitiesId]
      }
    }

    if (shouldUpdate) {
      updateActivities()
      updateSelectedActivities(userActivities)
      updatedList.current = true
    }
  }, [activities, updateActivities, updateSelectedActivities, userId, scoringConfig])

  /** HELPERS */

  const formatDate = useCallback((dateStr) => {
    if (!dateStr) return null
    return new Date(dateStr).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short', year: '2-digit' })
  }, [])

  const formatDateHour = useCallback((dateStr) => {
    if (!dateStr) return null
    return moment(dateStr).format('HH:mm')
  }, [])

  const statusByActivityId = useMemo(() => {
    const dict = {}
    for (let i = 0; i < moments.length; i++) {
      for (let j = 0; j < moments[i].length; j++) {
        const activity = moments[i][j]
        const id = activity.id
        dict[id] = {}

        // if (activity.scoringData) {
        //   dict[id].server = activity.published
        //   dict[id].serverScore = activity.scoringData.score.score
        //   dict[id].serverMinScore = activity.scoringData.context.minScore
        // }

        if (activity.published) {
          dict[id].border = activity._manuallyPublished ? '5px solid blue' : '10px solid green'
        } else {
          dict[id].opacity = 0.5
        }

        const simulation = scoringSimulation[id]
        if (simulation) {
          dict[id].outline = simulation.published ? '2px solid orange' : '2px solid black'
          dict[id].opacity = simulation.published ? 1 : 0.5
        }
      }
    }
    return dict
  }, [moments, scoringSimulation])

  return calendarDays.map((momentActivities) => (
    <MomentContainer key={momentActivities[0]?.calendarDayId}>
      <Actions>
        <IconButton
          variant="contained"
          color="warning"
          onClick={() => {
            simulateScoring(momentActivities[0]?.calendarDayId)
          }}
          size="small"
        >
          <AutoAwesomeIcon />
        </IconButton>
      </Actions>
      <MomentDate>
        {/* <span>{formatDate(momentActivities[0]?.creationDate)}</span> */}
        <span>{momentActivities[0]?.calendarDayId}</span>
      </MomentDate>
      {momentActivities[0]?.PlaceMomentId ? (
        momentActivities.map((activity) => (
          <ImageContainer
            key={activity.id}
            onClick={() => {
              setSelectedActivity({
                activity,
              })
            }}
          >
            <ActivityImage
              activity={activity}
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
              style={{
                border: statusByActivityId[activity.id]?.border,
                opacity: statusByActivityId[activity.id]?.opacity,
                outline: statusByActivityId[activity.id]?.outline,
              }}
            />
            <ActivityMetadata>
              {activity.isNSFW && <NSFWContainer>NSFW</NSFWContainer>}
              <Tooltip title="Algo min score allowed when computing this activity">
                <MinScoreContainer>
                  {activity.scoringData?.context ? <div>{activity.scoringData.context?.minScore}</div> : null}
                  {scoringSimulation[activity.id]?.scoringData?.context ? (
                    <div data-simulation>{scoringSimulation[activity.id]?.scoringData?.context?.minScore}</div>
                  ) : null}
                </MinScoreContainer>
              </Tooltip>
              <Tooltip title="Score">
                <ScoreContainer>
                  {activity.scoringData?.score ? <div>{activity.scoringData.score?.score}</div> : null}
                  {scoringSimulation[activity.id]?.scoringData?.score ? (
                    <div data-simulation>{scoringSimulation[activity.id]?.scoringData?.score?.score}</div>
                  ) : null}
                </ScoreContainer>
              </Tooltip>
              <Tooltip title="Upload group index">
                <GroupContainer>{'#' + groupByActivity[activity.id]}</GroupContainer>
              </Tooltip>
              <CreationDate>
                {/* <span>server: {formatDateHour(activity.createdAt)}</span> */}
                <div>
                  <span>{formatDateHour(activity.creationDate)}</span>
                  {activity._isLate ? (
                    <Tooltip title="Not Uploaded the same day">
                      <span>late</span>
                    </Tooltip>
                  ) : null}
                </div>
              </CreationDate>
            </ActivityMetadata>
            <ActivityDebug>
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  navigator.clipboard.writeText(activity.id)
                }}
              >
                {'#' + activity.id.split('-')[0]}
              </span>
            </ActivityDebug>
          </ImageContainer>
        ))
      ) : (
        <span>{momentActivities.length} activities with no PlaceMomentId</span>
      )}
    </MomentContainer>
  ))
}

const Actions = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

const ActivityDebug = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: red;
  overflow: hidden;

  > span {
    position: absolute;
    bottom: 3px;
    left: 3px;
    /* background: black; */
    color: white;
    background: rgba(0, 0, 0, 0.8);
    padding: 0 3px;
    border-radius: 3px;
    white-space: nowrap;

    &:hover {
      background: rgba(0, 0, 0, 1);
      cursor: pointer;
    }
  }
`

const WarningContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid red;
`

const NSFWContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  color: red;
  font-weight: bold;
  background: black;
  padding: 2px 8px;
  border-radius: 10px;
`

const ImageContainer = styled.div`
  position: relative;
  /* overflow: hidden; */
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  transition: box-shadow 0.2s ease-out;
  margin-top: 24px;
`

const MomentDate = styled.div`
  position: absolute;

  top: 50%;
  left: 0;
  z-index: 1;
  font-size: 12px;
  height: 0;
  width: 0;
  margin-left: -38px;

  > span {
    display: block;
    transform-origin: top center;
    transform: rotate(-90deg);
    text-align: center;
    width: 80px;
  }
`

const MomentContainer = styled.div`
  position: relative;
  transition: background-color 0.2s ease-out;
  align-items: flex-start;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  grid-gap: ${GRID_GAP}px;
  display: flex;
  flex-wrap: wrap;
  padding: ${GRID_GAP}px;
  padding-left: ${GRID_GAP + 12}px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);

    ${ImageContainer} {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
    }
  }
`

/**
 * METADATA
 */

const ActivityMetadata = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${(props) =>
    props.$redOverlay &&
    css`
      background: rgba(255, 0, 0, 0.5);
    `}

  ${(props) =>
    props.$blueOverlay &&
    css`
      background: rgba(0, 0, 255, 0.5);
    `}
`

const ScoreContainer = styled.div`
  background: black;
  top: 0;
  right: 0;
  position: absolute;
  font-size: 12px;
  padding: 2px 4px 2px 8px;
  border-bottom-left-radius: 12px;
  /* border-top-right-radius: 3px; */
  font-family: monospace;
  font-size: 10px;
  font-weight: bold;
  text-align: right;

  [data-simulation] {
    color: orange;
  }
`

const MinScoreContainer = styled.div`
  background: rgba(255, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: absolute;
  font-size: 12px;
  padding: 2px 8px 2px 4px;
  border-bottom-right-radius: 12px;
  /* border-top-right-radius: 3px; */
  font-family: monospace;
  font-size: 10px;
  font-weight: bold;
  text-align: left;

  [data-simulation] {
    color: orange;
  }
`

const GroupContainer = styled.div`
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  right: 0;
  position: absolute;
  font-size: 12px;
  padding: 2px 4px 2px 8px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 12px;
  font-family: monospace;
  font-size: 10px;
  font-weight: bold;
  text-align: right;
`

const CreationDate = styled.div`
  color: white;
  font-family: monospace;
  font-size: 9px;
  bottom: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 1);
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 2px 6px;
  z-index: -1;
  text-align: right;

  > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`
