import AddIcon from '@mui/icons-material/Add'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, LinearProgress, IconButton } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import FakeProfile from './FakeProfile'
import FakeProfileItem from './FakeProfileItem'
import api from '../../services/api'

export default function FakeProfiles() {
  const [loading, setLoading] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const [selectedProfile, setSelectedProfile] = useState()

  const [newUsername, setNewUsername] = useState(null)

  const confirm = useConfirm()

  const fetch = useCallback(async () => {
    setLoading(true)
    try {
      const res = await api.get('/admin/fake-profiles')
      const newData = res.data?.map(({ username, user, days }) => {
        return {
          username,
          user,
          days: days.map((day) => {
            return {
              username,
              userId: user.id,
              index: day.index,
              images: day.images.map((image) => {
                return {
                  ...image,
                  username,
                  userId: user.id,
                  activityId: image.activity?.id,
                  hasActivity: !!image.activty,
                }
              }),
            }
          }),
        }
      })
      setProfiles(newData)
      setSelectedProfile((prev) => {
        if (!prev) return prev
        const matchingProfile = newData.find((d) => d.username === prev.username)
        return matchingProfile
      })
    } catch (err) {
      console.error('Error while fetching fake profiles', err)
    }
    setLoading(false)
  }, [])

  const reset = useCallback(async () => {
    setLoading(true)
    try {
      await api.post('/admin/fake-profiles/reset')
      await fetch()
    } catch (error) {
      console.error('Error while reset', error)
      confirm({
        title: 'Error while reset',
        description: error?.response?.data ? JSON.stringify(error?.response?.data) : null,
        hideCancelButton: true,
      })
    }
    setLoading(false)
  }, [confirm, fetch])

  const createFakeUser = useCallback(async () => {
    setLoading(true)
    try {
      await api.post('/admin/fake-profiles', { username: newUsername })
      await fetch()
      setNewUsername(null)
    } catch (error) {
      console.error('Error while creating fake profile', error)
      confirm({
        title: 'Error while creating fake profile',
        description: error?.response?.data ? JSON.stringify(error?.response?.data) : null,
        hideCancelButton: true,
      })
    }
    setLoading(false)
  }, [newUsername, fetch, confirm])

  useEffect(() => {
    fetch()
  }, [fetch])

  const deleteProfile = useCallback(async () => {
    if (!selectedProfile?.username) {
      return
    }

    const confirmed = await confirm({
      description: 'This action cannot be reversed. Are you sure you want to delete this fake profile?',
    })
      .then(() => true)
      .catch(() => false)

    if (!confirmed) {
      return
    }

    setLoading(true)
    try {
      await api.post('/admin/fake-profiles/delete', { username: selectedProfile.username })
      setNewUsername(null)
      setSelectedItem(null)
      setSelectedProfile(null)
      await fetch()
    } catch (error) {
      console.error('Error while deleting fake profile', error)
      confirm({
        title: 'Error while deleting fake profile',
        description: error?.response?.data ? JSON.stringify(error?.response?.data) : null,
        hideCancelButton: true,
      })
    }
    setLoading(false)
  }, [confirm, selectedProfile?.username, fetch])

  return (
    <Container>
      {selectedItem && (
        <FakeProfileItem
          dayIndex={selectedItem.index}
          profile={selectedProfile}
          item={selectedItem}
          onCancel={() => setSelectedItem(undefined)}
          onUpdated={() => {
            fetch()
          }}
        />
      )}

      {newUsername != null && (
        <FakeUserForm style={{ padding: 24, borderLeft: '1px solid rgba(255,255,255,0.1)' }}>
          <h3>Create new Fake User</h3>
          <input onChange={(e) => setNewUsername(e.target.value.trim())} value={newUsername} />
          <Button
            variant="contained"
            color="primary"
            onClick={createFakeUser}
            size="small"
            disabled={!newUsername || loading}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setNewUsername(null)
            }}
            size="small"
            disabled={loading}
          >
            Cancel
          </Button>
        </FakeUserForm>
      )}
      <Main>
        <Header>
          {loading && (
            <LoadingContainer>
              <LinearProgress />
            </LoadingContainer>
          )}
          <Button variant="contained" onClick={reset} size="small" disabled={loading}>
            reset fake profiles
          </Button>
        </Header>
        <Content>
          {selectedProfile ? (
            <>
              <Row>
                <IconButton onClick={() => setSelectedProfile()}>
                  <ChevronLeftIcon />
                </IconButton>
                <h2>
                  {selectedProfile.username}
                  {selectedProfile?.user?.id && <UserId>{selectedProfile?.user?.id?.split('-')[0]}</UserId>}
                </h2>
                <div style={{ flex: '1 1', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
                  <Button variant="contained" color="error" onClick={deleteProfile} size="small" disabled={loading}>
                    Delete Profile {selectedProfile?.username}
                  </Button>
                </div>
              </Row>
              <FakeProfile profile={selectedProfile} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
            </>
          ) : (
            <Profiles>
              {profiles?.map((profile) => (
                <li key={profile.username} onClick={() => setSelectedProfile(profile)}>
                  <span>
                    {profile.username}
                    {profile?.user?.id && <UserId>{profile?.user?.id?.split('-')[0]}</UserId>}
                  </span>
                  <ChevronRightIcon />
                </li>
              ))}
              {newUsername === null && (
                <AddItem
                  onClick={() => {
                    setNewUsername('')
                  }}
                >
                  <AddIcon />
                  Add Fake User
                </AddItem>
              )}
            </Profiles>
          )}
        </Content>
      </Main>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex: 1 1;
`

const FakeUserForm = styled.div`
  display: flex;
  grid-gap: 12px;
  flex-direction: column;
  overflow-y: auto;
  width: 250px;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
`

const Content = styled.div`
  flex: 1;
  padding: 24px;
  overflow-y: auto;
`

const Profiles = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li {
    cursor: pointer;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s ease-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.06);
    }
  }

  > li + li {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 24px;
`

const Header = styled.div`
  padding: 4px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 10px;
  top: 100%;
  z-index: 100;
  pointer-events: none;
  z-index: 1000;
`

const UserId = styled.div`
  /* position: absolute; */
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  text-shadow: none;
  white-space: nowrap;
  bottom: calc(100% + 3px);
`

const AddItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  grid-gap: 12px;
  padding: 12px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`
