import { computeTransformationParams } from '../../services/imageKit'

export const mediaCDNTransformation = {
  imageMessage: ({ isNew, isDecayed } = {}) => ({
    height: 200,
    crop: 'maintain-ratio',
    blur: isNew ? 50 : 0,
    grayscale: isDecayed,
  }),
  mediaImage: ({ blurred } = {}) => ({
    width: blurred ? 50 : 150,
    height: blurred ? 50 : 150,
    crop: 'maintain-ratio',
    blur: blurred ? 50 : undefined,
  }),
}

export const imageKitMediaTypeBySourceType = {
  image: 'image',
  'video-thumbnail': 'image',
  'video-blurred-thumbnail': 'image',
  avatar: 'image',
  cover: 'image',
  background: 'image',
  mediaImage: 'image',
  mediaImageDetails: 'image',
  video: 'video',
  'video-blurred': 'video',
}

export function computeCDNUrl(options) {
  const params = options.transformation
    ? computeTransformationParams(options.transformation, imageKitMediaTypeBySourceType[options.type])
    : computeTransformationParams(null, imageKitMediaTypeBySourceType[options.type])
  const baseUri = `${process.env.REACT_APP_API_URL}/cdn/${options.type}/${options.id}`
  const url = params ? `${baseUri}?${params}` : baseUri
  return url
}

export function computeUrl(options, hash) {
  let url = computeCDNUrl(options)
  if (hash) {
    if (url.includes('?')) {
      url += `&hash=${hash}`
    } else {
      url += `?hash=${hash}`
    }
  }
  return url
}

export function computeKey(options, hash) {
  return [
    options.type,
    options.id,
    hash,
    computeTransformationParams(options.transformation, imageKitMediaTypeBySourceType[options.type]),
  ]
    .filter(Boolean)
    .join('_')
    .replace('tr=', '_')
    .replaceAll('.', '_')
    .replaceAll('%', '_')
}
