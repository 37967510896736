import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { Button, LinearProgress } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import api from '../../services/api'

export default function AlgoFilters() {
  const [loading, setLoading] = useState(false)
  const [blockList, setBlocklist] = useState('')
  const [malustList, setMalustList] = useState('')
  const confirm = useConfirm()

  const save = useCallback(
    async (key) => {
      setLoading(true)
      const value = key === 'BLOCK_LIST' ? blockList : malustList
      try {
        const res = await api.post('/admin/POV-filters', {
          key,
          value,
        })
        console.log(res.data)
        if (res.data.key === 'BLOCK_LIST') {
          setBlocklist(res.data.BLOCK_LIST)
        } else {
          setMalustList(res.data.MALUS_LIST)
        }
      } catch (err) {
        console.error(err)
        confirm({ title: 'Error while saving filters', hideCancelButton: true })
      }
      setLoading(false)
    },
    [confirm, blockList, malustList]
  )

  const fetch = useCallback(async () => {
    setLoading(true)
    try {
      const res = await api.get('/admin/POV-filters')
      console.log(res.data)
      setMalustList(res.data.MALUS_LIST)
      setBlocklist(res.data.BLOCK_LIST)
    } catch (err) {
      console.error(err)
      confirm({ title: 'Error while fetching filters', hideCancelButton: true })
    }
    setLoading(false)
  }, [confirm])

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <LinearProgress />
        </LoadingContainer>
      )}

      <Label>Block List</Label>
      <StyledTextarea value={blockList} onChange={(e) => setBlocklist(e.target.value)} disabled={loading} />
      <Button
        onClick={() => save('BLOCK_LIST')}
        style={{ width: '100%', fontSize: 12 }}
        variant="contained"
        size="small"
        disabled={loading}
      >
        Save
      </Button>
      <Label>Malus List</Label>
      <StyledTextarea value={malustList} onChange={(e) => setMalustList(e.target.value)} disabled={loading} />
      <Button
        onClick={() => save('MALUS_LIST')}
        style={{ width: '100%', fontSize: 12 }}
        variant="contained"
        size="small"
        disabled={loading}
      >
        Save
      </Button>
    </Container>
  )
}

const Container = styled.div`
  padding: 12px;
  color: white;
  flex: 1 1;
`

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: white;
`

const StyledTextarea = styled(TextareaAutosize)`
  background-color: transparent;
  color: white;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 3px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  outline: none;

  transition: border-color ease-out 0.2s;

  &:hover {
    border-color: rgba(255, 255, 255, 0.2);
  }

  &:focus {
    border-color: rgba(255, 255, 255, 0.5);
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 10px;
  /* top: 0; */
  z-index: 100;
  pointer-events: none;
`
