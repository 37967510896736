import { orderBy } from 'lodash'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { computeCalendarDayId } from '../pages/AlgoPOV/AlgoPOV.helpers'
import api from '../services/api'

export const StateContext = createContext(null)

export const useStateContext = () => useContext(StateContext)

function appendTag(allTags, tag, to) {
  to.push(tag)
  allTags.forEach((_tag) => {
    if (tag.id === _tag.TagId) {
      appendTag(allTags, _tag, to)
    }
  })
}

function appendTreeTag(allTags, tag) {
  allTags.forEach((_tag) => {
    if (tag.id === _tag.TagId) {
      if (!tag.children) {
        tag.children = []
      }
      const newTag = {
        ..._tag,
      }
      tag.children.push(newTag)
      appendTreeTag(allTags, newTag)
    }
  })
}

function formatActivity(activity) {
  const _uploadedCalendarDayId = activity.uploadedAt ? computeCalendarDayId(activity.uploadedAt) : null
  return {
    ...activity,
    _uploadedCalendarDayId,
    _isLate: _uploadedCalendarDayId && activity.calendarDayId && _uploadedCalendarDayId !== activity.calendarDayId,
    _manuallyPublished: activity.published && activity.publishedBy !== 'ALGO',
  }
}

export function StateProvider({ children, user, fetchMe }) {
  const [activity, setActivity] = useState()
  const [friends, setFriends] = useState([])
  const [users, setUsers] = useState({})
  const [activities, setActivities] = useState([])
  const [tags, setTags] = useState()
  const [usersClassificationData, setUsersClassificationData] = useState({})

  const fetched = useRef(false)

  const patchActivity = useCallback(async (activityId, data) => {
    const res = await api.patch(`admin/activities/${activityId}`, data)
    if (res.data?.activity) {
      setActivities((prevActivities) => {
        const updatedActivities = prevActivities.map((a) => {
          if (a.id === res.data.activity.id) {
            return {
              ...a,
              ...res.data.activity,
            }
          }
          return a
        })
        return updatedActivities
      })
    }
  }, [])

  const fetchActivities = useCallback(
    async (friendships = friends) => {
      if (!user?.id) return
      const activitiesResponses = await Promise.all(
        friendships
          .map((friendship) => {
            return api.get(`/activities/user/${friendship.user.id}`, {
              params: { limit: 200, details: 1 },
            })
          })
          .concat(
            api.get(`/activities/user/${user.id}`, {
              params: { limit: 200, details: 1 },
            })
          )
      )

      setActivities(
        activitiesResponses.reduce((acc, response) => {
          return acc.concat(
            response.data.activities.filter((a) => a.uploadStatus === 'COMPLETE').map((a) => formatActivity(a))
          )
        }, [])
      )
    },
    [friends, user?.id]
  )

  useEffect(() => {
    if (!user) return
    setUsers((prev) => ({ ...prev, [user?.id]: user }))
  }, [user])

  const fetchUsers = useCallback(async () => {
    const res = await api.get('/users/friendships/list')
    const usersDict = {}
    res.data.friendships.forEach((friendship) => {
      usersDict[friendship.user.id] = friendship.user
    })
    setUsers((prev) => ({ ...prev, ...usersDict }))
    setFriends(res.data.friendships)
    return res.data.friendships
  }, [])

  const fetchUsersClassificationData = useCallback(async () => {
    const usersData = {}
    await Promise.all(
      Object.keys(users).map(async (userId) => {
        const res = await api.get(`/activities/classification/users/${userId}/data`)
        usersData[userId] = res.data
      })
    )
    setUsersClassificationData(usersData)
  }, [users])

  useEffect(() => {
    if (Object.keys(users).length) {
      fetchUsersClassificationData()
    }
  }, [users, fetchUsersClassificationData])

  useEffect(() => {
    if (!user?.id || fetched.current) return
    async function fetch() {
      fetched.current = true
      try {
        const resFriendships = await fetchUsers()
        await fetchActivities(resFriendships)
      } catch (err) {
        fetched.current = false
      }
    }
    fetch()
  }, [fetchActivities, fetchUsers, user, user?.id])

  const fetchTags = useCallback(async () => {
    const res = await api.get('/activities/classification/tags')
    const formattedTags = res.data.tags.map((tag) => {
      const hasChildren = res.data.tags.some((_tag) => _tag.TagId === tag.id)
      return {
        ...tag,
        valid: !!tag.TagId || hasChildren,
        hasChildren,
      }
    })
    setTags(formattedTags)
  }, [])

  useEffect(() => {
    if (!user) return
    async function fetch() {
      await fetchTags()
    }
    fetch()
  }, [fetchTags, user])

  const tagsTree = useMemo(() => {
    if (!tags) return []
    const doneTags = []
    const nameOrderedTags = orderBy([...tags], ['name'], ['desc'])
    nameOrderedTags.forEach((tag) => {
      if (!tag.TagId && tag.valid) {
        const newTag = {
          ...tag,
        }
        doneTags.push(newTag)
        appendTreeTag(nameOrderedTags, newTag)
      }
    })
    nameOrderedTags.forEach((tag) => {
      if (tag.TagId && !doneTags.some((parentTag) => parentTag.id === tag.TagId)) {
        doneTags.push(tag)
      }
    })
    return doneTags
  }, [tags])

  const orderedTags = useMemo(() => {
    if (!tags) return []
    const doneTags = []
    const nameOrderedTags = orderBy([...tags], ['name'], ['desc'])

    nameOrderedTags.forEach((tag) => {
      if (!tag.TagId) {
        appendTag(nameOrderedTags, tag, doneTags)
      }
    })

    nameOrderedTags.forEach((tag) => {
      if (tag.TagId && !doneTags.some((parentTag) => parentTag.id === tag.TagId)) {
        doneTags.push(tag)
      }
    })

    return doneTags
  }, [tags])

  return (
    <StateContext.Provider
      value={{
        activity,
        setActivity,
        activityUser: users[activity?.UserId],
        tags,
        user,
        fetchTags,
        orderedTags,
        tagsTree,
        friends,
        users,
        fetchUsers,
        fetchMe,
        activities,
        fetchActivities,
        fetchUsersClassificationData,
        usersClassificationData,
        patchActivity,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}
