import { useCallback } from 'react'
import styled from 'styled-components'

import UserStreams from './UserStreams'
import { useStateContext } from '../../contexts/state.context'

export default function Users() {
  const { users, tags, fetchUsers, fetchMe, user } = useStateContext()

  const handleChange = useCallback(
    async (userId) => {
      if (userId === user?.id && userId && user?.id) {
        await fetchMe()
      } else {
        await fetchUsers()
      }
    },
    [user?.id, fetchUsers, fetchMe]
  )

  return (
    <Container>
      {Object.values(users).map((user) => (
        <UserStreams user={user} tags={tags} key={user.id} onChange={handleChange} style={{ margin: 12 }} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  /* display: flex; */
  flex: 1 1;
  flex-direction: column;
`
