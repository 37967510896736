import AddIcon from '@mui/icons-material/Add'
import { Button, LinearProgress, IconButton } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import FakeProfileItem from './FakeProfileItem'
import api from '../../services/api'

export default function FakeProfile({ profile, setSelectedItem, selectedItem }) {
  return (
    <Container>
      {profile?.days?.map((day) => (
        <div key={day.index}>
          <h3>day #{day.index}</h3>

          <Activities>
            {day?.images?.map((image) => (
              <Activity
                key={image.filename}
                style={{
                  backgroundImage: `url('https://catchup-staging.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
                    image.path
                  )}')`,
                }}
                onClick={() => {
                  setSelectedItem(image)
                }}
                $selected={selectedItem === image}
              >
                <ActivityHeader>
                  <ActivityId>{image.activity?.id?.split('-')[0]}</ActivityId>
                  <ActivityClassification>{image.classification}</ActivityClassification>
                  <ActivitySubtitle>
                    <Time>
                      <span>{image.hour.toString().padStart(2, '0')}</span>h
                      <span>{image.minute.toString().padStart(2, '0')}</span>
                    </Time>
                    <Weather>{image.weather}</Weather>
                  </ActivitySubtitle>
                </ActivityHeader>
                <Emojis>
                  {image.emojis.map((emoji) => (
                    <span key={emoji}>{emoji}</span>
                  ))}
                </Emojis>
              </Activity>
            ))}
            <AddItem
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setSelectedItem({ index: day.index })
              }}
            >
              <AddIcon />
            </AddItem>
          </Activities>
        </div>
      ))}
    </Container>
  )
}

const Container = styled.div``

const Activities = styled.div`
  display: flex;
  grid-gap: 24px;
  align-items: center;
  flex-wrap: wrap;
`

const Activity = styled.div`
  display: flex;
  width: 150px;
  height: 200px;
  background-size: cover;
  border-radius: 12px;
  padding: 12px;
  position: relative;
  text-shadow: 0 0 4px rgba(0, 0, 0, 1), 2px 2px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.3);

  ${(props) =>
    props.$selected &&
    css`
      border: 2px solid orange;
    `}
`

const Emojis = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  display: flex;
  flex-direction: row;
  grid-gap: 12px;

  > span {
    background: rgba(255, 255, 255, 0.1);
    /* backdrop-filter: blur(3px); */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.3);
  }
`

const ActivityId = styled.div`
  position: absolute;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  text-shadow: none;
  white-space: nowrap;
  bottom: calc(100% + 3px);
`

const UserId = styled.div`
  /* position: absolute; */
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  text-shadow: none;
  white-space: nowrap;
  bottom: calc(100% + 3px);
`

const ActivityHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 12px;
  width: 100%;
`

const ActivityClassification = styled.div`
  font-weight: bold;
  font-size: 18px;
`

const ActivitySubtitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 12px;
  font-size: 12px;
  font-weight: 500;
`

const Time = styled.div``

const Weather = styled.div``

const AddItem = styled(IconButton)`
  width: 50px;
  height: 50px;
`
