import { LinearProgress, Button } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { isEqual } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { JSONTree } from 'react-json-tree'
import styled from 'styled-components'

import { computeSimilarityDistance } from './AlgoPOV.helpers'
import AlgoPOVActivities from './AlgoPOVActivities'
import AlgoPOVParameters from './AlgoPOVParameters'
import ActivityImage from '../../components/ActivityImage'
import { useStateContext } from '../../contexts/state.context'
import api from '../../services/api'

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

const IMAGE_SIZE = 120
const GRID_GAP = 10

export default function AlgoPOV() {
  const { users, usersClassificationData, activities, fetchActivities, patchActivity } = useStateContext()
  const [loading, setLoading] = useState(true)
  const [simulationLoading, setSimulationLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('AlgoPOVUserId') || null)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [scoringSimulation, setScoringSimulation] = useState({})

  const [scoringConfig, setScoringConfig] = useState(null)
  const [fetchedScoreConfig, setFetchedScoreConfig] = useState(null)

  const [distanceStatus, setDistanceStatus] = useState('NONE')
  const [distanceComputingSelection, setDistanceComputingSelection] = useState()

  const fetchedRef = useRef(false)
  const [updating, setUpdating] = useState(false)

  const fetchScoreConfig = useCallback(async () => {
    const res = await api.get('admin/score-config')
    setFetchedScoreConfig(res.data?.scoreConfig || {})
  }, [])

  const patchScoreConfig = useCallback(async () => {
    if (updating) {
      return
    }
    setUpdating(true)
    const res = await api.patch('admin/score-config', { value: scoringConfig })
    setFetchedScoreConfig(res.data?.scoreConfig || {})
    setUpdating(false)
  }, [updating, scoringConfig])

  useEffect(() => {
    if (
      !loading &&
      scoringConfig &&
      Object.values(scoringConfig).length &&
      !isEqual(fetchedScoreConfig, scoringConfig) &&
      !updating
    ) {
      // patchScoreConfig(scoringConfig)
    }
  }, [scoringConfig, loading, fetchedScoreConfig, updating, patchScoreConfig])

  useEffect(() => {
    localStorage.setItem('AlgoPOVUserId', selectedUserId)
  }, [selectedUserId])

  useEffect(() => {
    if (!fetchedRef.current) {
      fetchedRef.current = true
      fetchScoreConfig()
    }
  }, [fetchScoreConfig])

  useEffect(() => {
    if (
      loading &&
      activities.length &&
      Object.keys(users).length > 0 &&
      Object.keys(usersClassificationData).length > 0 &&
      fetchedScoreConfig
    ) {
      setLoading(false)
    }
  }, [loading, users, usersClassificationData, activities, fetchedScoreConfig])

  const resetServerScoring = useCallback(async () => {
    if (!selectedUserId) return
    setLoading(true)
    const userActivities = activities.filter(
      (activity) => activity.UserId === selectedUserId && !!activity.PlaceMomentId
    )
    await api.post('/admin/reset-score', {
      userId: selectedUserId,
      activitiesId: userActivities.map((a) => a.id).filter(Boolean),
    })
    await fetchActivities()
    setLoading(false)
  }, [selectedUserId, activities, fetchActivities])

  const resetLast2WeeksServerScoring = useCallback(async () => {
    if (!selectedUserId) return
    setLoading(true)
    const twoWeeksAgo = moment().subtract(2, 'weeks')
    const userActivities = activities.filter(
      (activity) =>
        activity.UserId === selectedUserId &&
        !!activity.PlaceMomentId &&
        moment(activity.creationDate).isAfter(twoWeeksAgo)
    )
    await api.post('/admin/reset-score', {
      userId: selectedUserId,
      activitiesId: userActivities.map((a) => a.id).filter(Boolean),
    })
    await fetchActivities()
    setLoading(false)
  }, [selectedUserId, activities, fetchActivities])

  const analyzeImages = useCallback(async () => {
    const userActivities = activities.filter(
      (activity) => activity.UserId === selectedUserId && !!activity.PlaceMomentId
    )
    const activitiesId = userActivities.map((a) => a.id).filter(Boolean)

    await api.post('/admin/activities/analyze', { activitiesId })
  }, [activities, selectedUserId])

  const simulateScoring = useCallback(
    async (calendarDayId) => {
      if (!selectedUserId) return
      setSimulationLoading(true)
      const userActivities = activities.filter(
        (activity) =>
          activity.UserId === selectedUserId &&
          !!activity.PlaceMomentId &&
          (!calendarDayId || activity.calendarDayId === calendarDayId)
      )

      const activitiesId = userActivities.map((a) => a.id).filter(Boolean)

      const res = await api.post('/admin/score/simulate', {
        userId: selectedUserId,
        activitiesId,
        scoreConfig: scoringConfig,
      })
      const newScoringSimulation = {}
      res.data?.result?.activitiesPatch?.forEach(({ id, data }) => {
        newScoringSimulation[id] = data
      })
      setScoringSimulation(newScoringSimulation)
      setSimulationLoading(false)
    },
    [selectedUserId, activities, scoringConfig]
  )

  const onSelect = useCallback(
    (data) => {
      if (distanceStatus === 'SELECTING') {
        if (distanceComputingSelection) {
          const result = computeSimilarityDistance(data.activity, distanceComputingSelection)
          setDistanceComputingSelection(null)
          setDistanceStatus('NONE')
          window.alert(JSON.stringify(result, null, 2))
        } else {
          setDistanceComputingSelection(data.activity)
        }
      } else {
        setSelectedActivity(data)
      }
    },
    [distanceStatus, distanceComputingSelection]
  )

  const togglePublish = useCallback(async () => {
    if (selectedActivity?.activity) {
      const updatedActivity = await patchActivity(selectedActivity?.activity.id, {
        published: !selectedActivity?.activity.published,
      })
      if (updatedActivity?.id === selectedActivity.activity.id) {
        setSelectedActivity((prev) => ({
          ...prev,
          activity: {
            ...prev.activity,
            ...updatedActivity,
          },
        }))
      }
    }
  }, [selectedActivity, patchActivity])

  return (
    <Container>
      {selectedActivity && (
        <DetailContainer>
          <DetailImage>
            <ActivityImage
              style={{ margin: 12 }}
              activity={selectedActivity.activity}
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
            />
            <ActivityDebug>
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  navigator.clipboard.writeText(selectedActivity?.activity?.id)
                }}
              >
                {'#' + selectedActivity?.activity?.id?.split('-')[0]}
              </span>
            </ActivityDebug>
            <ActivityPublishToggler>
              <Button variant="contained" onClick={togglePublish} size="small">
                {selectedActivity?.activity?.published ? 'unpublish' : 'publish'}
              </Button>
            </ActivityPublishToggler>
          </DetailImage>
          <Content>
            <ExtraDataContainer>
              <h3>Activity Score</h3>
              <Row>
                {selectedActivity.activity.scoringData?.score && (
                  <DataGroup>{JSON.stringify(selectedActivity.activity.scoringData.score, null, 2)}</DataGroup>
                )}
              </Row>
              <h3>Scoring context</h3>
              <Row>
                {selectedActivity.activity.scoringData?.context && (
                  <DataGroup>{JSON.stringify(selectedActivity.activity.scoringData.context, null, 2)}</DataGroup>
                )}
              </Row>
              {scoringSimulation[selectedActivity.activity.id] ? (
                <>
                  <h3>Scoring simulation</h3>
                  <Row>
                    <DataGroup>{JSON.stringify(scoringSimulation[selectedActivity.activity.id], null, 2)}</DataGroup>
                  </Row>
                </>
              ) : null}
            </ExtraDataContainer>
            <JSONTree
              style={{ padding: 0, margin: 0 }}
              data={selectedActivity}
              theme={{
                extend: theme,
              }}
              shouldExpandNodeInitially={(keypath, data, level) => {
                if (level < 1) {
                  return true
                }

                if (keypath[keypath.length - 2] === 'user' || (level === 1 && keypath[0] === 'activity')) {
                  return true
                }
              }}
            />
          </Content>
        </DetailContainer>
      )}
      <Main>
        <Header>
          {(loading || updating || simulationLoading) && (
            <LoadingContainer>
              <LinearProgress />
            </LoadingContainer>
          )}
          {Object.keys(users).length > 0 && (
            <Select
              value={selectedUserId}
              onChange={(event) => {
                setSelectedUserId(event.target.value)
              }}
            >
              {Object.values(users).map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <Actions>
            <Button
              variant="contained"
              onClick={() => {
                analyzeImages()
              }}
              size="small"
            >
              Analyze images
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setDistanceStatus('SELECTING')
              }}
              size="small"
              disabled={distanceStatus !== 'NONE'}
            >
              Distance
            </Button>
            <Button
              variant="contained"
              onClick={() => simulateScoring()}
              size="small"
              disabled={simulationLoading || !selectedUserId}
            >
              Score
            </Button>
            {/* <Button
            variant="contained"
            onClick={resetLast2WeeksServerScoring}
            size="small"
            disabled={loading || !selectedUserId}
          >
            Reset last 2 weeks server scoring
          </Button>
          <Button variant="contained" onClick={resetServerScoring} size="small" disabled={loading || !selectedUserId}>
            Reset server scoring
          </Button> */}
          </Actions>
        </Header>

        <ImagesContainer>
          <Images>
            {!loading && selectedUserId && users[selectedUserId] && scoringConfig && (
              <AlgoPOVActivities
                userId={selectedUserId}
                setSelectedActivity={onSelect}
                selectedActivity={selectedActivity}
                scoringConfig={scoringConfig}
                scoringSimulation={scoringSimulation}
                simulateScoring={simulateScoring}
              />
            )}
          </Images>
        </ImagesContainer>
      </Main>
      {!loading && (
        <AlgoPOVParameters
          defaultValue={fetchedScoreConfig}
          onChange={(value) => {
            setScoringConfig(value)
          }}
          onSave={() => {
            patchScoreConfig()
          }}
        />
      )}
    </Container>
  )
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 12px;
`

const ExtraDataContainer = styled.div`
  h3:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 12px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  grid-gap: 12px;

  > div:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    padding-left: 12px;
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 10px;
  top: 100%;
  z-index: 100;
  pointer-events: none;
  z-index: 1000;
`

const Main = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 4px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1;
`

const ImagesContainer = styled.div`
  overflow-y: scroll;
  flex: 1 1;
`

const Images = styled.div`
  grid-gap: ${GRID_GAP}px;
  display: flex;
  flex-wrap: wrap;
  padding: ${GRID_GAP}px;
  flex: 1 1;
`

/**
 * DETAILS
 */

const ActivityPublishToggler = styled.div`
  position: absolute;
  right: 3px;
  bottom: 3px;
  z-index: 10;
  transform: scale(0.8);
`

const ActivityDebug = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: red;
  overflow: hidden;

  > span {
    position: absolute;
    bottom: 3px;
    left: 3px;
    /* background: black; */
    color: white;
    background: rgba(0, 0, 0, 0.8);
    padding: 0 3px;
    border-radius: 3px;
    white-space: nowrap;

    &:hover {
      background: rgba(0, 0, 0, 1);
      cursor: pointer;
    }
  }
`

const DetailImage = styled.div`
  background: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* position: fixed; */
  /* z-index: 10; */
  /* top: 6px; */
  /* left: 6px; */
  /* max-height: 650px; */
  /* overflow: auto; */
  /* max-width: calc(100% - 12px); */

  border-radius: 6px;
  /* background: black; */
  background-color: rgba(0, 0, 0, 0.3);
  /* backdrop-filter: blur(30px); */
  /* width: 300px; */
`

const Content = styled.div`
  white-space: pre-wrap;
  font-size: 10px;
  padding: 12px 24px;
  overflow: auto;
  font-family: monospace;
  font-size: 8px;
`

const DataTitle = styled.div`
  color: white;
`

const DataGroup = styled.div``
